const INITIAL_STATE = {
    uid: 0,
    token: '',
    login: false,
    active: false,
    loading: false,
    demo_mode: false,
    free_mode: false,
};

export default INITIAL_STATE;
