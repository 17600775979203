import {Button, Modal} from "antd";
import {InfoOutlined} from "@ant-design/icons";
import React from "react";

function showModalDemoWarning() {
    Modal.warning({
            title: 'This is FREE User Mode',
            width: 800,
            content: <>
                <h3>
                    This feature is disabled in FREE user mode. To get full access to all features, modules and community traders
                    you need to subscribe as a member.
                </h3>
            </>,
            footer: (_, {OkBtn, CancelBtn}) => (
                <>
                    <Button onClick={(e) => {
                        e.preventDefault();
                        window.location.href = 'https://cdzv.com/#pricing';
                    }}><InfoOutlined/>Get Access</Button>
                    <OkBtn/>
                </>
            ),
        },
    );
}

export {
    showModalDemoWarning,
}