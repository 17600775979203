const exchanges = [
    {
        name: "Binance",
        ccxt_name: "binance",
        description: "BINANCE crypto exchange",
        logo: "https://panel.cdzv.com/logos/Binance.svg",
        password: false,
    },
    {
        name: "OKX ",
        ccxt_name: "okx",
        description: "OKX crypto exchange",
        logo: "https://panel.cdzv.com/logos/okx.svg",
        password: true,
    },
    {
        name: "ByBit",
        ccxt_name: "bybit",
        description: "ByBit crypto exchange",
        logo: "https://panel.cdzv.com/logos/Bybit.svg",
        password: false,
    },
    {
        name: "HTX (soon)",
        ccxt_name: "htx",
        description: "HTX crypto exchange",
        logo: "https://panel.cdzv.com/logos/HTX.svg",
        password: false,
    },
    {
        name: "Kucoin (soon)",
        ccxt_name: "kucoin",
        description: "Kucoin crypto exchange",
        logo: "https://panel.cdzv.com/logos/Kucoin.svg",
        password: true,
    },
];

export default exchanges;