import Search from "antd/es/input/Search";
import {Badge, Card, Col, Divider, Row, Space, Tag} from "antd";
import {useEffect, useState} from "react";

import gateways_list from "./gateways_list";

function Gateways(props) {

    const [search, setSearch] = useState(gateways_list);
    const [searchValue, setSearchValue] = useState('')

    const onSearch = e => {
        // console.log(e.target.value);
        setSearchValue(e.target.value);
        setSearch(gateways_list.filter(module => {
            let add_filter_text = '';
            if (module.crypto) add_filter_text += 'Crypto';
            if (module.stocks) add_filter_text += 'Stocks';
            return (
                module.name.toLowerCase().includes(e.target.value.toLowerCase()) ||
                module.description.toLowerCase().includes(e.target.value.toLowerCase()) ||
                module.brokers.some(broker => broker.toLowerCase().includes(e.target.value.toLowerCase())) ||
                add_filter_text.toLowerCase().includes(e.target.value.toLowerCase())
            )
        }));
    }

    useEffect(() => {
        setSearch(gateways_list);
    }, []);

    return (
        <>
            <Search
                size="large"
                allowClear
                style={{marginTop: "40px", width: "400px"}}
                placeholder="input search text"
                onChange={onSearch}
                enterButton
                value={searchValue}
            />
            <Row
                gutter={[16, 16]}
                align={"middle"}
                justify={"center"}
                style={{marginBottom: '30px'}}
            >
                {search.map((module, index) => {
                    return (
                        <Col span={18}>
                            <Badge.Ribbon
                                text={module.free ? "FREE" : "PAID"}
                                color={module.free ? "green" : "blue"}
                            >
                                <Card
                                    type="inner"
                                    bordered={true}
                                    // hoverable
                                    // onClick={() => {
                                    //     window.open(module.url[0], '_blank');
                                    // }}
                                    title={<>
                                        <h2 style={{color: '#b21a2d'}}>
                                            <Space direction={"horizontal"} wrap>
                                                {module.name}
                                                {module.crypto &&
                                                    <Tag color={"geekblue"} bordered={false}>
                                                        Crypto
                                                    </Tag>
                                                }
                                                {module.stocks &&
                                                    <Tag color={"green"} bordered={false}>
                                                        Stocks
                                                    </Tag>
                                                }
                                            </Space>
                                        </h2>
                                    </>}
                                    style={{width: "100%"}}>
                                    {/*<Divider*/}
                                    {/*    style={{marginTop: "10px"}}*/}
                                    {/*>Description:</Divider>*/}
                                    <p style={{fontSize: '16px'}}>
                                        {module.description}
                                    </p>
                                    {/*<Divider>URL:</Divider>*/}
                                    <a href={module.url} target="_blank" rel="noreferrer">🔗 Go to site</a>
                                    <Divider>Brokers:</Divider>
                                    <Space direction={"horizontal"} wrap>
                                        {module.brokers.map((broker, index) => {
                                            return (
                                                <Tag key={index}
                                                     onClick={() => {
                                                         onSearch({target: {value: broker}})
                                                     }}
                                                >
                                                    {broker}
                                                </Tag>
                                            )
                                        })}
                                    </Space>
                                </Card>
                            </Badge.Ribbon>
                        </Col>
                    )
                })}
            </Row>
        </>
    );
}

export default Gateways;