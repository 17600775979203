import {useEffect, useState} from "react";
import API from "../../../api/user";
import CustomerProfileCard from "../../components/CustomerProfileCard";
import {Skeleton} from "antd";

function Profile(props) {

    const apiUser = new API();

    const [userProfile, setUserProfile] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        apiUser.getUserProfile().then((response) => {
            if (response.result) {
                // console.log(response);
                setUserProfile(JSON.parse(response.data));
                // console.log(response.data);
            } else {
                // console.log(response.error);
                apiUser.logout();
            }
            setLoading(false);
        });
    }, []);

    /*
    {"ID":1,"is_active":1,"is_admin":1,"is_send_mail":1,"discord":"cdzv.com","tv_user":"CDZV","date_subscription_start":"2099-02-28T23:00:00.000Z","date_subscription_end":"2099-02-28T23:00:00.000Z","first_name":"Yev","last_name":"Gen","phone":null,"email":"lexus@cdzv.com","name":"Life time","code":"LIFETIME","days":9999,"date_end":"2126-07-16T22:00:00.000Z","days_left":1020301}
     */
    return (
        <>
            <Skeleton loading={loading}>
                <CustomerProfileCard customerData={userProfile}/>
            </Skeleton>
        </>
    );
}

export default Profile;