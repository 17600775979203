import React, {useEffect, useState} from "react";

// Router
import {
    Route,
    Routes,
} from "react-router-dom";

import {mobXStore, observer} from "../store";

// HOC
import RouteWithAuth from "../hoc/RouteWithAuth";
import Index from "../view/pages/Index";
import Login from "../view/pages/Login";
import Error404 from "../view/pages/404/404";

function Router() {

    const [isLogin, setIsLogin] = useState(true);

    useEffect(() => {
        setIsLogin(mobXStore.getKey('login'))
    }, []);

    // checkLogin
    useEffect(() => {
        setIsLogin(mobXStore.getKey('login'))
        // console.log('LOGIN', isLogin);
    }, [mobXStore.store.get('login')]);

    return (
        <Routes>

            <Route exact path='/' element={<RouteWithAuth isPublic={false}
                                                          isLogin={isLogin}
                                                          redirectPath="/login"/>}
            >
                <Route exact path='/' element={<Index/>}/>
            </Route>

            <Route exact path='/login' element={<Login/>}/>
            <Route path='*' element={<Error404/>}/>

        </Routes>
    );
}

export default observer(Router);