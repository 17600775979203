import Search from "antd/es/input/Search";
import {Badge, Card, Col, Divider, Image, Row, Space, Tag} from "antd";
import {useEffect, useState} from "react";

import cdzv_modules from "./cdzv_modules";
import {Link} from "react-router-dom";

function Indicators(props) {

    const [search, setSearch] = useState(cdzv_modules);
    const [searchValue, setSearchValue] = useState('not_payed');

    const onSearch = e => {
        // console.log(e.target.value);
        setSearchValue(e.target.value);
        setSearch(cdzv_modules.filter(module => {
            return (
                module.name.toLowerCase().includes(e.target.value.toLowerCase()) ||
                module.description.toLowerCase().includes(e.target.value.toLowerCase()) ||
                module.tags.some(tag => tag.toLowerCase().includes(e.target.value.toLowerCase()))
            )
        }));
    }

    useEffect(() => {
        setSearch(cdzv_modules.filter(module => {
            return (
                module.name.toLowerCase().includes('not_payed') ||
                module.description.toLowerCase().includes('not_payed') ||
                module.tags.some(tag => tag.toLowerCase().includes('not_payed'))
            )
        }));
    }, []);

    return (
        <>
            <Search
                size="large"
                allowClear
                style={{marginTop: "40px", width: "500px"}}
                placeholder="input search text"
                onChange={onSearch}
                enterButton
                value={searchValue}
            />
            <div style={{marginTop: '-60px'}}>
                <Space direction={'horizontal'}>
                    <Link
                        to={"https://docs.cdzv.com/code-zero-visual-trading_en/user-guide/creating-and-testing-the-strategy"}
                        target={"_blank"}>
                        <h2>🔗How to start? [EN]</h2>
                    </Link>
                    <Link
                        to={"https://docs.cdzv.com/code-zero-visual-trading_ua/posibnik-koristuvacha/stvorennya-ta-testuvannya-strategiyi"}
                        target={"_blank"}>
                        <h2>🔗How to start? [UA]</h2>
                    </Link>
                    <Link
                        to={"https://docs.cdzv.com/code-zero-visual-trading_ru/rukovodstvo-polzovatelya/sozdanie-i-testirovanie-strategii"}
                        target={"_blank"}>
                        <h2>🔗How to start? [RU]</h2>
                    </Link>
                </Space>
                <Row gutter={24} justify={"center"}>
                    <Col style={{marginBottom: 30, marginTop: -30}}>
                        <Space.Compact block direction={"horizontal"} wrap>
                            {/*<Tag key={'t1'}*/}
                            {/*     color={"green"}*/}
                            {/*     style={{cursor: "pointer", fontSize: '16px'}}*/}
                            {/*     onClick={() => {*/}
                            {/*         onSearch({*/}
                            {/*             target: {*/}
                            {/*                 value: "cdzvcore"*/}
                            {/*             }*/}
                            {/*         })*/}
                            {/*     }}*/}
                            {/*>*/}
                            {/*    CDZV core*/}
                            {/*</Tag>*/}
                            <Tag key={'t2'}
                                 color={searchValue === "pro_payed" ? "blue-inverse" : "gray"}
                                 checked={searchValue === "pro_payed"}
                                 style={{cursor: "pointer", fontSize: '16px'}}
                                 onClick={() => {
                                     onSearch({
                                         target: {
                                             value: "pro_payed"
                                         }
                                     })
                                 }}
                            >
                                {searchValue === "pro_payed" ? <strong>CDZV Toolkit <span
                                    style={{fontWeight: 700, color: "#faf753"}}>Pro</span></strong> : <>CDZV
                                    Toolkit <span style={{fontWeight: 700, color: "#faf753"}}>Pro</span></>}
                            </Tag>
                            <Tag key={'t2free'}
                                 color={searchValue === "not_payed" ? "blue-inverse" : "gray"}
                                 checked={searchValue === "not_payed"}
                                 style={{cursor: "pointer", fontSize: '16px'}}
                                 onClick={() => {
                                     onSearch({
                                         target: {
                                             value: "not_payed"
                                         }
                                     })
                                 }}
                            >
                                {searchValue === "not_payed" ? <strong>CDZV Toolkit <span
                                    style={{fontWeight: 700, color: "#4afacc"}}>Free</span></strong> : <>CDZV
                                    Toolkit <span style={{fontWeight: 700, color: "#4afacc"}}>Free</span></>}
                            </Tag>
                            <Tag key={'t3'}
                                 color={searchValue === "cdzvindicators" ? "blue-inverse" : "gray"}
                                 checked={searchValue === "cdzvindicators"}
                                 style={{cursor: "pointer", fontSize: '16px'}}
                                 onClick={() => {
                                     onSearch({
                                         target: {
                                             value: "cdzvindicators"
                                         }
                                     })
                                 }}
                            >
                                {searchValue === "cdzvindicators" ?
                                    <strong>CDZV Indicators <span
                                        style={{fontWeight: 700, color: "#4afacc"}}>Free</span></strong> : <>CDZV Indicators <span
                                        style={{fontWeight: 700, color: "#4afacc"}}>Free</span></>}
                            </Tag>
                            {/*<Tag key={'t4'}*/}
                            {/*     color={searchValue === "martingrid" ? "green" : "gray"}*/}
                            {/*     checked={searchValue === "martingrid"}*/}
                            {/*     style={{cursor: "pointer", fontSize: '16px'}}*/}
                            {/*     onClick={() => {*/}
                            {/*         onSearch({*/}
                            {/*             target: {*/}
                            {/*                 value: "martingrid"*/}
                            {/*             }*/}
                            {/*         })*/}
                            {/*     }}*/}
                            {/*>*/}
                            {/*    {searchValue === "martingrid" ?*/}
                            {/*        <strong>MartinGrid</strong> : <>Martin<strong>Grid</strong></>}*/}
                            {/*</Tag>*/}
                            <Tag key={'t5'}
                                 color={searchValue === "modified" ? "blue-inverse" : "gray"}
                                 style={{cursor: "pointer", fontSize: '16px'}}
                                 onClick={() => {
                                     onSearch({
                                         target: {
                                             value: "modified"
                                         }
                                     })
                                 }}
                            >
                                {searchValue === "modified" ? <strong>Modified Indicators <span
                                    style={{fontWeight: 700, color: "#4afacc"}}>Free</span></strong> : <>Modified
                                    Indicators <span style={{fontWeight: 700, color: "#4afacc"}}>Free</span></>}
                            </Tag>
                        </Space.Compact>
                    </Col>
                </Row>
            </div>
            <Row
                gutter={[8, 8]}
                align={"middle"}
                justify={"center"}
                style={{marginBottom: '30px'}}
            >
                {search.map((module, index) => {
                    return (
                        <Col span={20}>
                            <Badge.Ribbon
                                placement="start"
                                text={searchValue === 'pro_payed' ? 'PRO' : 'FREE'}
                                color={searchValue === 'pro_payed' ? 'blue' : 'red'}
                            >
                                <Badge.Ribbon
                                    placement={'end'}
                                    text={module.strategy ? "STRATEGY" : "INDICATOR"}
                                    color={module.strategy ? "blue" : "darkgreen"}
                                >
                                    <Card
                                        type="inner"
                                        bordered={true}
                                        // hoverable
                                        // onClick={() => {
                                        //     window.open(module.url[0], '_blank');
                                        // }}
                                        title={<>
                                            <h2 style={{color: '#b21a2d'}}>
                                                {module.name}
                                            </h2>
                                        </>}
                                        style={{width: "100%"}}>
                                        {/*<Divider*/}
                                        {/*    style={{marginTop: "10px"}}*/}
                                        {/*>Description:</Divider>*/}
                                        <p style={{fontSize: '16px'}}>
                                            {module.description}
                                        </p>
                                        <Divider>Install URL:</Divider>
                                        <Space direction={"horizontal"} wrap>
                                            {module.url.map((url, index) => {
                                                return (
                                                    <>
                                                        <a href={url} target="_blank"
                                                           rel="noreferrer">🔗 <strong>TradingView</strong> <Image
                                                            src={"https://cdzv.com/strategy_img/CMI_Condition_Manager__Indicator_by_CDZV_COM__TradingView_2024-04-16_13-10-26.png"}
                                                            preview={false}
                                                            width={200}
                                                        />
                                                        </a>
                                                    </>
                                                )
                                            })}
                                        </Space>
                                        <Divider>Manual:</Divider>
                                        <Space direction={"horizontal"}>
                                            {module.manual_url.en !== "" &&
                                                <a href={module.manual_url.en} target="_blank" rel="noreferrer"> 🇬🇧
                                                    Manual
                                                    EN</a>
                                            }
                                            {module.manual_url.ua !== "" &&
                                                <a href={module.manual_url.ua} target="_blank" rel="noreferrer"> 🇺🇦
                                                    Manual
                                                    UA</a>
                                            }
                                            {module.manual_url.ru !== "" &&
                                                <a href={module.manual_url.ru} target="_blank" rel="noreferrer"> 🏳️
                                                    Manual
                                                    RU</a>
                                            }
                                        </Space>
                                        <Divider>Tags:</Divider>
                                        <Space direction={"horizontal"} wrap>
                                            {module.tags.map((tag, index) => {
                                                return (
                                                    <Tag key={index}
                                                         onClick={() => {
                                                             onSearch({target: {value: tag}})
                                                         }}
                                                    >
                                                        {tag}
                                                    </Tag>
                                                )
                                            })}
                                        </Space>
                                    </Card>
                                </Badge.Ribbon>
                            </Badge.Ribbon>
                        </Col>
                    )
                })}
            </Row>
        </>
    );
}

export default Indicators;