import React, {createRef, useEffect, useRef, useState} from 'react';
import {
    Button,
    Card,
    Col, ConfigProvider,
    Divider,
    Image,
    Input,
    Modal,
    Popconfirm,
    Row,
    Select,
    Space,
    Spin, Switch,
    Table,
    Tooltip
} from 'antd';
import {Content} from "antd/es/layout/layout";

import exchanges from "./exchanges";
import {
    AlertOutlined,
    CaretDownOutlined,
    CaretUpOutlined, ClearOutlined, CloseOutlined,
    CopyOutlined,
    DeleteOutlined,
    EditOutlined, FullscreenExitOutlined, SearchOutlined
} from "@ant-design/icons";
import API from "../../../api/user";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {Link} from "react-router-dom";
import {mobXStore} from "../../../store";
import {showModalDemoWarning} from "../../../Helpers";

const filterOption = (input, option) =>
    (option?.search_label ?? '').toLowerCase().includes(input.toLowerCase());

// Connectors component
function Connectors(props) {

    const apiUser = new API();

    const exchanges_list = exchanges.map((exchange, index) => {
        return {
            value: exchange.ccxt_name,
            search_label: exchange.name,
            label: <span>{exchange.name}</span>
        }
    })

    const inputNameChange = createRef();
    const inputName = createRef();
    const inputPubApiKey = createRef();
    const inputSecApiKey = createRef();
    const inputPassword = createRef();

    const [demoMode, setDemoMode] = useState(false);

    const [spin_connection, setSpinConnection] = useState(false);

    const [showPasswordInput, setShowPasswordInput] = useState(false);

    const [showAddConnectionModal, setShowAddConnectionModal] = useState(false);

    const [typeExchange, setTypeExchange] = useState('spot');
    const [ccxtExchange, setCcxtExchange] = useState(exchanges_list[0].value);

    const [dataSource, setDataSource] = useState([]);
    const [loading, setLoading] = useState(false);

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const sortIcon = (sorted) => {
        // alert(JSON.stringify(sorted))
        if (sorted['sortOrder'] === 'descend') {
            return (<CaretDownOutlined
                style={{
                    color: '#1677ff'
                }}
            />)
        } else if (sorted['sortOrder'] === 'ascend') {
            return (<CaretUpOutlined
                style={{
                    color: '#1677ff'
                }}
            />)
        } else {
            return (<FullscreenExitOutlined
                style={{
                    color: 'white',
                }}
            />)
        }
    }

    const getColumnSearchProps = (dataIndex, tooltip_text = '') => ({
        filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters, close}) => (
            <div
                style={{
                    // backgroundColor: '#0f0fa8',
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Space direction={'horizontal'}>
                    <Input
                        ref={searchInput}
                        placeholder={tooltip_text}
                        value={selectedKeys[0]}
                        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{
                            marginBottom: 8,
                            width: 200,
                            display: 'block',
                        }}
                    />
                    <Button
                        type="button"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        <CloseOutlined/>
                    </Button>
                </Space>
                <br/>
                <Space direction={'horizontal'}>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined/>}
                        size="small"
                        style={{
                            width: 115,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && (handleReset(clearFilters) || handleSearch(selectedKeys, confirm, dataIndex) || close())}
                        size="small"
                        style={{
                            width: 115,
                        }}
                    >
                        <ClearOutlined/> Reset
                    </Button>
                    {/*<Button*/}
                    {/*    type="link"*/}
                    {/*    size="small"*/}
                    {/*    onClick={() => {*/}
                    {/*        confirm({*/}
                    {/*            closeDropdown: false,*/}
                    {/*        });*/}
                    {/*        setSearchText(selectedKeys[0]);*/}
                    {/*        setSearchedColumn(dataIndex);*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    Filter*/}
                    {/*</Button>*/}
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#f6adb5' : 'white',
                }}
            />
        ),
        sortIcon: (sorted) => sortIcon(sorted),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        // render: (text) =>
        //     searchedColumn === dataIndex ? (
        //         <Highlighter
        //             highlightStyle={{
        //                 backgroundColor: '#ffc069',
        //                 padding: 0,
        //             }}
        //             searchWords={[searchText]}
        //             autoEscape
        //             textToHighlight={text ? text.toString() : ''}
        //         />
        //     ) : (
        //         text
        //     ),
    });

    useEffect(() => {
    }, [typeExchange, ccxtExchange]);

    function handleDeleteConnection(key) {
        if (demoMode) {
            showModalDemoWarning();
            return;
        }

        setLoading(true);
        apiUser.deleteUserExchange({id: key}).then((response) => {
            if (response.result) {
                getUserExchange();
                Modal.success({
                    title: 'Success',
                    content: 'Connection deleted successfully',
                });
            } else {
                // console.log(response.error);
                Modal.error({
                    title: 'Error',
                    content: 'Connection delete error: ' + response.error,
                });
            }
            setLoading(false);
        });
    }

    function handleChangeActive(key, is_active) {
        if (demoMode) {
            showModalDemoWarning();
            return;
        }

        setLoading(true);
        apiUser.changeActiveExchangeConnector({id: key, is_active: is_active}).then((response) => {
            if (response.result) {
                getUserExchange();
                // Modal.success({
                //     title: 'Success',
                //     content: 'Connection status changed successfully',
                // });
            } else {
                // console.log(response.error);
                Modal.error({
                    title: 'Error',
                    content: 'Connection status change error: ' + response.error,
                });
            }
            setLoading(false);
        });
    }

    // Save new connection to DB
    const saveNewConnectToDB = () => {

        if (demoMode) {
            showModalDemoWarning();
            return;
        }

        const name = inputName.current.input.value;
        const pubKey = inputPubApiKey.current.input.value;
        const secKey = inputSecApiKey.current.input.value;
        const password = inputPassword.current.input.value || '';
        const exchange = ccxtExchange;
        const type = typeExchange;

        if (pubKey && secKey) {
            if (pubKey.length < 10 || secKey.length < 10) {
                Modal.error({
                    title: 'Error',
                    content: 'API keys too short',
                });
                return;
            }
        } else {
            Modal.error({
                title: 'Error',
                content: 'All fields must be completed',
            });
            return;
        }

        setLoading(true);
        setSpinConnection(true);

        // Test connection to the exchange (user data)
        apiUser.testConnection({
            exchange: exchange,
            market: type,
            api_key: pubKey,
            secret_key: secKey,
            password: password
        }).then((response) => {

            if (response.result === true) {
                setSpinConnection(false);
                // Create connection to the exchange in DB
                apiUser.createExchangeConnector({
                    exchange: exchange,
                    market: type,
                    name: name,
                    api_key: pubKey,
                    api_secret_key: secKey,
                    password: password
                }).then((response) => {
                    if (response.result) {
                        getUserExchange();
                        setShowAddConnectionModal(false);
                        Modal.success({
                            title: 'Success',
                            content: 'Connection created successfully',
                        });
                    } else {
                        // console.log(response.error);
                        Modal.error({
                            title: 'Error',
                            content: 'Connection created error: ' + response.error,
                        });
                    }
                    setLoading(false);
                });
            } else {
                setSpinConnection(false);
                Modal.error({
                    title: 'Error',
                    content: 'Connection to the exchange failed. Check the correctness of the entered data.'
                });
                setLoading(false);
            }
        }).catch((error) => {
            setSpinConnection(false);
            Modal.error({
                title: 'Error',
                content: 'Connection to the exchange failed. Check the correctness of the entered data.'
            });
            setLoading(false);
        });
    }

    // Save new name to DB
    const saveNewNameToDB = (ID) => {

        if (demoMode) {
            showModalDemoWarning();
            return;
        }

        const name = inputNameChange.current.input.value;

        if (name && name.length >= 5) {
            setLoading(true);
            apiUser.changeNameExchangeConnector({id: ID, name: name}).then((response) => {
                if (response.result) {
                    getUserExchange();
                    Modal.success({
                        title: 'Success',
                        content: 'Connection name changed successfully',
                    });
                } else {
                    // console.log(response.error);
                    Modal.error({
                        title: 'Error',
                        content: 'Connection name change error: ' + response.error,
                    });
                }
                setLoading(false);
            });
        } else {
            Modal.error({
                title: 'Error',
                content: 'Connection name must be at least 5 characters',
            });
        }
    }

    // Show modal for edit connection name
    function handleEdit(record) {
        Modal.confirm({
            title: 'Change connection name',
            content: <Input
                ref={inputNameChange}
                placeholder="Enter New Connection name"
                style={{width: '100%'}}
                defaultValue={record.name}
            />,
            onOk() {
                saveNewNameToDB(record.key);
            },
        });
    }

    useEffect(() => {
        setDemoMode(mobXStore.getKey('demo_mode'));
        getUserExchange();
    }, []);

    function getUserExchange() {
        setLoading(true);
        apiUser.getUserExchange().then((response) => {
            if (response.result) {
                // console.log(response);
                setDataSource(JSON.parse(response.data));
                // console.log(response.data);
            } else {
                // console.log(response.error);
                apiUser.logout();
            }
            setLoading(false);
        });
    }

    // show modal for add new connection
    const modalAddConnection = () => {
        return (
            <Modal
                open={showAddConnectionModal}
                title={<strong>🆕 Create new Connection</strong>}
                style={{top: 20}}
                height={600}
                width={700}
                onOk={() => saveNewConnectToDB()}
                onCancel={() => setShowAddConnectionModal(false)}
            >
                <Card>
                    <Space
                        direction="vertical"
                        size="middle"
                        style={{
                            display: 'flex',
                        }}>
                        <p style={{marginTop: "0px"}}><strong>
                            Connect to <Link to={"https://accounts.binance.com/en/register?ref=OSHDFY9J"}
                                             target={"_blank"}>Binance</Link>, <Link
                            to={"https://www.okx.com/ua/join/53775592"} target={"_blank"}>OKX</Link>, <Link
                            to={"https://www.bybit.com/uk-UA/invite/?ref=0NN1MN"}
                            target={"_blank"}>ByBit</Link>, <Link
                            to={"https://www.htx.com/en-us/v/register/double-invite/web/?inviter_id=11345710&invite_code=rc2c5223"}
                            target={"_blank"}>HTX/Huobi</Link>, <Link
                            to={"https://ibkr.com/referral/ievgenii739"} target={"_blank"}>Interactive
                            Brokers</Link>
                        </strong>
                        </p>
                        <p style={{marginTop: "0px"}}>
                            <strong>Add this IP to the WhiteList on the exchange:&nbsp;</strong>
                            <Tooltip title="Copy to clipboard (delimiter: COMMA)">
                                <CopyToClipboard
                                    text="35.159.150.45,18.158.109.198,18.192.16.1,18.194.182.121,18.195.146.52,18.196.51.174,3.120.18.53,3.126.1.162,3.67.127.144,3.67.248.49,3.72.134.34,3.73.186.33,3.76.243.37,3.76.136.15"
                                    onCopy={() => {
                                        Modal.success({
                                            title: 'Success',
                                            content: 'IP address list copied to clipboard',
                                        })
                                    }}
                                >
                                    <CopyOutlined/>
                                </CopyToClipboard>
                            </Tooltip>
                            <Tooltip title="Copy to clipboard (delimiter: SPACE)">
                                <CopyToClipboard
                                    text="35.159.150.45 18.158.109.198 18.192.16.1 18.194.182.121 18.195.146.52 18.196.51.174 3.120.18.53 3.126.1.162 3.67.127.144 3.67.248.49 3.72.134.34 3.73.186.33 3.76.243.37 3.76.136.15"
                                    onCopy={() => {
                                        Modal.success({
                                            title: 'Success',
                                            content: 'IP address list copied to clipboard',
                                        })
                                    }}
                                >
                                    <CopyOutlined/>
                                </CopyToClipboard>
                            </Tooltip>
                            <pre style={{color: "#b21a2d"}}>
                                35.159.150.45 18.158.109.198 18.192.16.1 18.194.182.121 18.195.146.52<br/>
                                18.196.51.174 3.120.18.53 3.126.1.162 3.67.127.144 3.67.248.49<br/>
                                3.72.134.34 3.73.186.33 3.76.243.37 3.76.136.15
                            </pre>
                            <Divider/>
                        </p>
                        <Input.Group compact>
                            <Input disabled value="Connector name"
                                   style={{width: '26%', color: 'rgba(0, 0, 0, 0.65)', cursor: 'auto'}}/>
                            <Input
                                ref={inputName}
                                placeholder="Connector name"
                                style={{width: '74%'}}
                            />
                        </Input.Group>
                        <Input.Group compact>
                            <Input disabled value="Exchange"
                                   style={{width: '26%', color: 'rgba(0, 0, 0, 0.65)', cursor: 'auto'}}/>

                            <Select
                                showSearch
                                onChange={(value) => {
                                    setShowPasswordInput(exchanges.find(exchange => exchange.ccxt_name === value).password);
                                    setCcxtExchange(value);
                                }}
                                style={{width: '74%'}}
                                // value={ccxtExchange}
                                options={exchanges_list}
                                filterOption={filterOption}
                                // defaultValue={exchanges_list[0].value}
                            />
                        </Input.Group>
                        <Input.Group compact>
                            <Input disabled value="Account type"
                                   style={{width: '26%', color: 'rgba(0, 0, 0, 0.65)', cursor: 'auto'}}/>

                            <Select
                                onChange={(value) => setTypeExchange(value)}
                                style={{width: '74%'}}
                                value={typeExchange}
                                options={[
                                    {value: 'spot', label: <span>SPOT</span>},
                                    {value: 'future', label: <span>FUTURES</span>}
                                ]}
                                defaultValue={'spot'}
                            />
                        </Input.Group>
                        <Input.Group compact>
                            <Input disabled value="Public API Key"
                                   style={{width: '26%', color: 'rgba(0, 0, 0, 0.65)', cursor: 'auto'}}/>
                            <Input
                                ref={inputPubApiKey}
                                placeholder="Public Key"
                                style={{width: '74%'}}
                            />
                        </Input.Group>
                        <Input.Group compact>
                            <Input disabled value="Secret API Key"
                                   style={{width: '26%', color: 'rgba(0, 0, 0, 0.65)', cursor: 'auto'}}/>
                            <Input
                                ref={inputSecApiKey}
                                placeholder="Secret Key"
                                style={{width: '74%'}}
                            />
                        </Input.Group>
                        <Input.Group compact style={showPasswordInput ? {display: 'unset'} : {display: 'none'}}>
                            <Input disabled value="API Password"
                                   style={{width: '26%', color: 'rgba(0, 0, 0, 0.65)', cursor: 'auto'}}/>
                            <Input
                                ref={inputPassword}
                                placeholder="API Password"
                                style={{width: '74%'}}
                            />
                        </Input.Group>
                    </Space>
                    <Spin spinning={spin_connection} size="large">
                        {spin_connection && <span>exchange connection verification...</span>}
                    </Spin>
                </Card>
                <strong style={{color: "#d05f6d"}}>* Fill in all fields and click <strong>'OK'</strong> to
                    create the connection.</strong>
                <p style={{marginTop: "10px", color: "#617af8", fontWeight: 700}}>
                    <AlertOutlined style={{color: 'red'}}/> IMPORTANT: For correct work of the system with deals in LONG
                    and SHORT it is necessary to set up an account on the exchange in the hedging mode.
                    <a href={"https://docs.cdzv.com/code-zero-visual-trading_en/user-guide/how-to-enable-hedge-mode"}
                       target={"_blank"}><span style={{color: "red"}}>🔗Read here</span></a>
                </p>
            </Modal>
        )
    }

    const columns = [
        {
            title: 'Active',
            dataIndex: 'is_active',
            align: 'center',
            width: '60px',
            render: (_, record) => (<Switch size="large" checked={record.is_active}
                                            onChange={() => handleChangeActive(record.key, !record.is_active)}/>),
            // defaultSortOrder: 'descend',
            sortIcon: (sorted) => sortIcon(sorted),
            sorter: (a, b) => a.is_active - b.is_active
        },
        {
            title: 'Name',
            dataIndex: 'name',
            align: 'center',
            width: '25%',
            render: (text) => {
                return <><strong>{text}</strong></>
            },
            defaultSortOrder: 'ascend',
            sortIcon: (sorted) => sortIcon(sorted),
            sorter: (a, b) => a.name.localeCompare(b.name),
            ...getColumnSearchProps('name', 'Search by Connector name'),
        },
        {
            title: 'Exchange',
            dataIndex: 'exchange',
            width: '15%',
            align: 'center',
            render: (text, record) => {
                const exchange = exchanges.find(exchange => exchange.ccxt_name === text);
                return (
                    <Image height={"30px"} src={exchange.logo} preview={false}/>
                )
            },
            // defaultSortOrder: 'descend',
            sortIcon: (sorted) => sortIcon(sorted),
            sorter: (a, b) => a.exchange.localeCompare(b.exchange),
            // defaultSortOrder: 'descend',
            // sorter: (a, b) => a.exchange.localeCompare(b.exchange)
            ...getColumnSearchProps('exchange', 'Search by Exchange name'),
        },
        {
            title: 'Type',
            dataIndex: 'type',
            align: 'center',
            width: '100px',
            render: (text) => {
                if (text.toLowerCase() === 'spot') {
                    return <><strong style={{color: '#009700'}}>{text.toUpperCase()}</strong></>
                } else {
                    return <><strong style={{color: '#617af8'}}>{text.toUpperCase()}</strong></>
                }
            },
            sortIcon: (sorted) => sortIcon(sorted),
            // defaultSortOrder: 'descend',
            sorter: (a, b) => a.type.localeCompare(b.type),
            ...getColumnSearchProps('type', 'Search by Account type'),
        },
        {
            title: 'Pub Key',
            dataIndex: 'pub_key',
            width: '15%',
            align: 'center',
            // defaultSortOrder: 'descend',
            sortIcon: (sorted) => sortIcon(sorted),
            sorter: (a, b) => a.pub_key.localeCompare(b.pub_key),
            ...getColumnSearchProps('pub_key', 'Search by Public API Key'),
        },
        {
            title: '',
            dataIndex: 'operation',
            align: 'center',
            width: '50px',
            render: (_, record) =>
                dataSource.length >= 1 ? (
                    <>
                        <Space direction={"horizontal"}>
                            <Button style={{background: '#d1d9da'}}
                                    onClick={() => handleEdit(record)}>
                                <EditOutlined/>
                            </Button>
                            <Button type="text" style={{background: '#e80e19', color: "white"}}>
                                <Popconfirm title="Sure to delete connection?"
                                            onConfirm={() => handleDeleteConnection(record.key)}>
                                    <DeleteOutlined/>
                                </Popconfirm>
                            </Button>
                        </Space>
                    </>
                ) : null,
        },
    ];


    return (
        <>
            {modalAddConnection()}
            <Content>
                <Row gutter={8} justify={"center"} align={"top"}>
                    <Col span={24}>
                        <ConfigProvider
                            theme={{
                                components: {
                                    Table: {
                                        // fontSize: "16px",
                                        fontFamily: 'Courier New',
                                        headerBorderRadius: '10px',
                                        headerColor: '#ffffff',
                                        headerBg: '#272762',
                                        bodySortBg: '#ffffff',
                                        headerSortHoverBg: '#272762',
                                        headerSortActiveBg: '#272762',
                                        cellFontSizeSM: "16px",
                                        cellPaddingInline: '10px',
                                        cellPaddingBlockSM: '5px',
                                        cellPaddingInlineSM: '5px',
                                        colorLinkActive: '#b21a2d',
                                    },
                                },
                            }}
                        >
                            <Table
                                rowKey={record => record.key}
                                size="small"
                                pagination={{hideOnSinglePage: true, pageSize: 13}}
                                loading={loading}
                                title={() =>
                                    <Row align={"middle"} justify={"end"}>
                                        <Button
                                            onClick={() => setShowAddConnectionModal(true)}
                                            type="primary"
                                            style={{
                                                marginTop: '0%',
                                                marginBottom: '0%',
                                            }}
                                        >
                                            Connect new Exchange
                                        </Button>
                                    </Row>
                                }
                                style={{
                                    marginTop: '2%',
                                    marginLeft: '5%',
                                    marginRight: '5%',
                                }}
                                bordered
                                dataSource={dataSource}
                                columns={columns}
                                onRow={(record) => {
                                    return {
                                        // onClick: () => handleEdit(record.key), // click row
                                        onDoubleClick: () => handleEdit(record), // double click row
                                        // onContextMenu: event => {
                                        // }, // right button click row
                                        // onMouseEnter: event => {
                                        // }, // mouse enter row
                                        // onMouseLeave: event => {
                                        // }, // mouse leave row
                                    };
                                }}
                            />
                        </ConfigProvider>
                    </Col>
                </Row>
            </Content>
        </>
    );
}

export default Connectors;