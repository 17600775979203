import React, {useEffect, useRef} from 'react';
import {Card, Button, Row, Col, Descriptions, Modal, Input, Tooltip, Image} from 'antd';
import API from "../../../api/user";

import moment from 'moment';
import {Link} from "react-router-dom";
import {EditTwoTone, QuestionCircleOutlined} from "@ant-design/icons";

const CustomerProfileCard = ({customerData}) => {

    const apiUser = new API();

    const refDiscordName = useRef(null);
    const refTelegramName = useRef(null);
    const refTelegramAIID = useRef(null);

    const {
        email,
        discord,
        telegram_name,
        telegram_ai_id,
        tv_user,
        name,
        date_subscription_start,
        days_left,
    } = customerData;

    const [visibleDiscord, setVisibleDiscord] = React.useState(false);
    const [visibleTelegram, setVisibleTelegram] = React.useState(false);
    const [visibleTelegramAIID, setVisibleTelegramAIID] = React.useState(false);
    const [errorMessages, setErrorMessages] = React.useState('');
    const [inputValueDiscord, setInputValueDiscord] = React.useState('');
    const [inputValueTelegram, setInputValueTelegram] = React.useState('');
    const [inputValueTelegramAIID, setInputValueTelegramAIID] = React.useState('');

    const showModalDiscord = () => {
        setInputValueDiscord('');
        setErrorMessages('');
        setVisibleDiscord(true);
    }

    const showModalTelegram = () => {
        setInputValueTelegram('');
        setErrorMessages('');
        setVisibleTelegram(true);
    }

    const showModalTelegramAI = () => {
        setInputValueTelegramAIID('');
        setErrorMessages('');
        setVisibleTelegramAIID(true);
    }

    useEffect(() => {
        if (!discord || discord.length === 0) {
            showModalDiscord();
            setErrorMessages('Discord name is required to access our Discord server where you will find, news, like-minded people, help and support.');
        }
    }, []);

    const changeDiscordName = () => {
        const new_discord = refDiscordName.current.input.value;

        if (new_discord.length < 5) {
            setErrorMessages('Discord name should be at least 5 characters long');
            return;
        }

        apiUser.changeDiscordName({discord: new_discord}).then((response) => {
            if (response.result) {
                setErrorMessages('');
                setVisibleDiscord(false);
                window.location = '/?updateprofile';
            } else {
                setErrorMessages(response.error);
                apiUser.logout();
            }
        });
    }

    const changeTelegramdName = () => {
        const new_telegram = refTelegramName.current.input.value;

        if (new_telegram.length < 5) {
            setErrorMessages('Telegram name should be at least 5 characters long');
            return;
        }

        apiUser.changeTelegramName({telegram: new_telegram}).then((response) => {
            if (response.result) {
                setErrorMessages('');
                setVisibleTelegram(false);
                window.location = '/?updateprofile';
            } else {
                setErrorMessages(response.error);
                apiUser.logout();
            }
        });
    }

    const changeTelegramdNameAI = () => {
        const new_telegram = refTelegramAIID.current.input.value;

        if (new_telegram.length < 5) {
            setErrorMessages('Telegram ChatID should be at least 5 characters long');
            return;
        }

        apiUser.changeTelegramAI_ID({telegram_ai_id: new_telegram}).then((response) => {
            if (response.result) {
                setErrorMessages('');
                setVisibleTelegramAIID(false);
                window.location = '/?updateprofile';
            } else {
                setErrorMessages(response.error);
                apiUser.logout();
            }
        });
    }

    return (
        <>
            <Modal
                title="Enter your Discord name"
                open={visibleDiscord}
                onOk={changeDiscordName}
                onCancel={() => {
                    setErrorMessages('');
                    setVisibleDiscord(false);
                }}
            >
                <Input
                    value={inputValueDiscord}
                    ref={refDiscordName}
                    placeholder="Enter your Discord Name"
                    onChange={(e) => setInputValueDiscord(e.target.value)}
                />
                {errorMessages !== '' ? <p style={{color: 'red'}}>{errorMessages}</p> : null}
            </Modal>

            <Modal
                title="Enter your Telegram name"
                open={visibleTelegram}
                onOk={changeTelegramdName}
                onCancel={() => {
                    setErrorMessages('');
                    setVisibleTelegram(false);
                }}
            >
                <Input
                    value={inputValueTelegram}
                    ref={refTelegramName}
                    placeholder="Enter your Telegram Name"
                    onChange={(e) => setInputValueTelegram(e.target.value)}
                />
                {errorMessages !== '' ? <p style={{color: 'red'}}>{errorMessages}</p> : null}
            </Modal>

            <Modal
                title="Enter your Telegram chat ID with @AnySearchBot_bot"
                open={visibleTelegramAIID}
                onOk={changeTelegramdNameAI}
                onCancel={() => {
                    setErrorMessages('');
                    setVisibleTelegramAIID(false);
                }}
            >
                <Input
                    value={inputValueTelegramAIID}
                    ref={refTelegramAIID}
                    placeholder="Enter your Telegram chat ID with @AnySearchBot_bot"
                    onChange={(e) => setInputValueTelegramAIID(e.target.value)}
                />
                {errorMessages !== '' ? <p style={{color: 'red'}}>{errorMessages}</p> : null}
            </Modal>

            <Row gutter={[8, 8]} align={"middle"} justify={"center"}>
                <Card
                    style={{width: '60%', marginTop: 50, textAlign: 'center'}}
                >
                    <Row gutter={[16, 16]} align={"middle"} justify={"center"}>
                        <Col span={24}>
                            <Descriptions bordered column={1} title={email}>
                                {/*<Descriptions.Item label="Email">{email}</Descriptions.Item>*/}
                                <Descriptions.Item
                                    label={<>Discord Name (<Link to={"https://discord.gg/cu5tPqz4ft"} target={"_blank"}>Join
                                        our Discord server</Link>)
                                        <br/>
                                        <span style={{color: "red"}}>
                                            * Must be filled out to gain access to the community
                                        </span>
                                    </>}>
                                    <Row gutter={8} align={"middle"} justify={"center"}>
                                        <Col><strong>{discord}</strong></Col>
                                        <Col>
                                            <Button
                                                // type="primary"
                                                onClick={showModalDiscord}
                                            >
                                                <EditTwoTone/>
                                            </Button>
                                        </Col>
                                    </Row>
                                </Descriptions.Item>
                                <Descriptions.Item label="TV User Profile Name">
                                    <strong>{tv_user}</strong>
                                </Descriptions.Item>
                                <Descriptions.Item
                                    label={<>Telegram Name<br/>
                                        <span style={{color: "red"}}>
                                            * Must be filled to access the channel with signals from strategies
                                        </span>
                                    </>}>
                                    <Row gutter={8} align={"middle"} justify={"center"}>
                                        <Col><strong>{telegram_name}</strong></Col>
                                        <Col>
                                            <Button
                                                // type="primary"
                                                onClick={showModalTelegram}
                                            >
                                                <EditTwoTone/>
                                            </Button>
                                        </Col>
                                    </Row>
                                </Descriptions.Item>
                                <Descriptions.Item
                                    label={<>
                                        @AnySearchBot_bot ChatId (<Link to={"https://t.me/AnySearchBot_bot"}
                                                                        target={"_blank"}>Join
                                        @AnySearchBot_bot</Link>) <Tooltip
                                        overlayInnerStyle={{
                                            width: '500px',
                                            // backgroundColor: '#efefefef',
                                            // color: 'black',
                                        }}
                                        title={<div>
                                            <h3>
                                                How to get full access:<br/><br/>

                                                1. Follow the link and launch the bot.<br/>
                                                2. Call the <span style={{color: 'red'}}>/info</span> command<br/>
                                                3. The ChatID will be listed at the bottom of the message.<br/>
                                                4. Enter the ChatID in the user panel under Profile: @AnySearchBot_bot
                                                ChatId.<br/><br/>

                                                Enjoy!
                                            </h3>
                                            <Image width={480}
                                                   src={"https://cdzv.com/strategy_img/Telegram_2024-06-02_19-02-16.png"}/>
                                        </div>}>
                                        <QuestionCircleOutlined/>
                                    </Tooltip>
                                        <br/>
                                        <span style={{color: "red"}}>
                                            * Must be filled to gain full access to the AI agent
                                        </span>
                                    </>}>
                                    <Row gutter={8} align={"middle"} justify={"center"}>
                                        <Col><strong>{telegram_ai_id}</strong></Col>
                                        <Col>
                                            <Button
                                                // type="primary"
                                                onClick={showModalTelegramAI}
                                            >
                                                <EditTwoTone/>
                                            </Button>
                                        </Col>
                                    </Row>
                                </Descriptions.Item>
                                <Descriptions.Item label="Subscription package">
                                    <strong>{name}</strong>
                                    <br/>
                                    <Link
                                        to={'https://billing.stripe.com/p/login/3csdTkgWidAObTy7ss?prefilled_email=' + email}
                                        target={"_blank"}>Subscription management</Link>
                                </Descriptions.Item>
                                <Descriptions.Item label="Subscription start date">
                                    <strong>{moment(date_subscription_start, 'YYYY-MM-DD').format('DD-MM-YYYY')}</strong>
                                </Descriptions.Item>
                                <Descriptions.Item label="Days left on the subscription">
                                    {days_left > 0 && days_left}
                                    {days_left <= 0 &&
                                        <>
                                            <span style={{color: 'red'}}>Subscription has expired</span>
                                            <br/>
                                            <Link to={'https://cdzv.com'} target={"_blank"}><strong>🔗 Renew
                                                subscription</strong></Link>
                                        </>
                                    }
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                    </Row>
                </Card>
            </Row>
        </>
    );
};

export default CustomerProfileCard;