const cdzv_modules = [
    {
        name: "CONDITION MANAGER Pro",
        description: "CONDITION MANAGER INDICATOR is a key module of the complex, which allows you to skillfully combine various external indicators to form trading signals or calculate constants. Thanks to its power, you can build most of your trading strategies using just CMI as if it's your Swiss Army knife in the world of trading.",
        tags: ["indicator", "cmi", "condition manager", 'rule', 'constant', 'cdzv', 'toolkit', 'cdzvcore', 'pro_payed'],
        url: [
            "https://www.tradingview.com/script/I5L4fNXm-cm-i-condition-manager/",
        ],
        strategy: false,
        free: false,
        manual_url: {
            en: "https://docs.cdzv.com/code-zero-visual-trading_en/the-components/indicators/condition-manager",
            ua: "https://docs.cdzv.com/code-zero-visual-trading_ua/komponenti/indikatori/condition-manager",
            ru: "https://docs.cdzv.com/code-zero-visual-trading_ru/komponenty/indikatory/condition-manager",
        }
    }, {
        name: "CONDITION MANAGER Free",
        description: "CONDITION MANAGER INDICATOR is a key module of the complex, which allows you to skillfully combine various external indicators to form trading signals or calculate constants. Thanks to its power, you can build most of your trading strategies using just CMI as if it's your Swiss Army knife in the world of trading.",
        tags: ["indicator", "cmi", "condition manager", 'rule', 'constant', 'cdzv', 'toolkit', 'cdzvcore', 'not_payed'],
        url: [
            "https://www.tradingview.com/script/pLNndihl-CM-I-Condition-Manager-Free/",
        ],
        strategy: false,
        free: true,
        manual_url: {
            en: "https://docs.cdzv.com/code-zero-visual-trading_en/the-components/indicators/condition-manager",
            ua: "https://docs.cdzv.com/code-zero-visual-trading_ua/komponenti/indikatori/condition-manager",
            ru: "https://docs.cdzv.com/code-zero-visual-trading_ru/komponenty/indikatory/condition-manager",
        }
    }, {
        name: "CONDITION MANAGER STRATEGY Pro",
        description: "The CONDITION MANAGER STRATEGY module tests your ideas on historical data and organizes algo trading.",
        tags: ["strategy", "cms", "condition manager", 'backtest', 'cdzv', 'toolkit', 'cdzvcore', 'pro_payed'],
        url: [
            "https://www.tradingview.com/script/uEXjjK9d-cm-s-condition-manager-strategy/",
        ],
        strategy: true,
        free: false,
        manual_url: {
            en: "https://docs.cdzv.com/code-zero-visual-trading_en/the-components/strategies/condition-manager-strategy",
            ua: "https://docs.cdzv.com/code-zero-visual-trading_ua/komponenti/strategiyi/condition-manager-strategy",
            ru: "https://docs.cdzv.com/code-zero-visual-trading_ru/komponenty/strategii/condition-manager-strategy",
        }
    }, {
        name: "CONDITION MANAGER STRATEGY Free",
        description: "The CONDITION MANAGER STRATEGY module tests your ideas on historical data and organizes algo trading.",
        tags: ["strategy", "cms", "condition manager", 'backtest', 'cdzv', 'toolkit', 'cdzvcore', 'not_payed'],
        url: [
            "https://www.tradingview.com/script/GJD1ixHw-CM-S-Condition-Manager-Strategy-Free/",
        ],
        strategy: true,
        free: true,
        manual_url: {
            en: "https://docs.cdzv.com/code-zero-visual-trading_en/the-components/strategies/condition-manager-strategy",
            ua: "https://docs.cdzv.com/code-zero-visual-trading_ua/komponenti/strategiyi/condition-manager-strategy",
            ru: "https://docs.cdzv.com/code-zero-visual-trading_ru/komponenty/strategii/condition-manager-strategy",
        }
    }, {
        name: "STATE MACHINE",
        description: "STATE MACHINE allows you to create strategies based on a sequence of events. This module automatically moves step by step through the logical chain of conditions and waits for the necessary time intervals between events until all the criteria necessary for the purchase are met.",
        tags: ["indicator", "stm", "state machine", 'rule', 'cdzv', 'toolkit', 'cdzvcore', 'pro_payed'],
        url: [
            "https://www.tradingview.com/script/JixAN1M0-cm-i-state-machine/",
        ],
        strategy: false,
        free: false,
        manual_url: {
            en: "https://docs.cdzv.com/code-zero-visual-trading_en/the-components/indicators/state-machine",
            ua: "https://docs.cdzv.com/code-zero-visual-trading_ua/komponenti/indikatori/state-machine",
            ru: "https://docs.cdzv.com/code-zero-visual-trading_ru/komponenty/indikatory/state-machine",
        }
    }, {
        name: "MARTIN GRID STRATEGY Pro",
        description: "MARTIN GRID STRATEGY is a powerful tool for the TradingView platform that allows you to create and test various trading strategies based on an order grid. This module is especially useful for strategies that utilize the principle of position averaging (Martingale).",
        tags: ["strategy", "mgr", "martin grid", 'grid', 'martingale', "martingrid", "backtest", 'cdzv', 'toolkit', 'pro_payed'],
        url: [
            "https://www.tradingview.com/script/ZM7k7uMV-CM-S-MARTIN-GRID-Strategy/",
        ],
        strategy: true,
        free: false,
        manual_url: {
            en: "https://docs.cdzv.com/code-zero-visual-trading_en/the-components/strategies/martin-grid-strategy",
            ua: "https://docs.cdzv.com/code-zero-visual-trading_ua/komponenti/strategiyi/martin-grid-strategy",
            ru: "https://docs.cdzv.com/code-zero-visual-trading_ru/komponenty/strategii/martin-grid-strategy",
        }
    }, {
        name: "MARTIN GRID STRATEGY Free",
        description: "MARTIN GRID STRATEGY is a powerful tool for the TradingView platform that allows you to create and test various trading strategies based on an order grid. This module is especially useful for strategies that utilize the principle of position averaging (Martingale).",
        tags: ["strategy", "mgr", "martin", "martin grid", 'grid', 'martingale', "martingrid", "backtest", 'cdzv', 'toolkit', 'not_payed'],
        url: [
            "https://www.tradingview.com/script/JbzqhhU5-CM-S-Martin-Grid-Free/",
        ],
        strategy: true,
        free: true,
        manual_url: {
            en: "https://docs.cdzv.com/code-zero-visual-trading_en/the-components/strategies/martin-grid-strategy",
            ua: "https://docs.cdzv.com/code-zero-visual-trading_ua/komponenti/strategiyi/martin-grid-strategy",
            ru: "https://docs.cdzv.com/code-zero-visual-trading_ru/komponenty/strategii/martin-grid-strategy",
        }
    }, {
        name: "MARTIN GRID DYNAMIC STRATEGY Pro",
        description: "MARTIN GRID DYNAMIC STRATEGY is a powerful tool for the TradingView platform that allows you to create and test various trading strategies based on an order grid. This module is particularly useful for a variety of grid strategies.",
        tags: ["strategy", "mgr", "martin grid", 'grid', 'dynamic', 'martingale', "martingrid", "backtest", 'cdzv', 'toolkit', 'pro_payed'],
        url: [
            "https://www.tradingview.com/script/nLFWBUl4-CM-S-Martin-Grid-Dynamic/",
        ],
        strategy: true,
        free: false,
        manual_url: {
            en: "https://docs.cdzv.com/code-zero-visual-trading_en/the-components/strategies/martin-grid-strategy",
            ua: "https://docs.cdzv.com/code-zero-visual-trading_ua/komponenti/strategiyi/martin-grid-strategy",
            ru: "https://docs.cdzv.com/code-zero-visual-trading_ru/komponenty/strategii/martin-grid-strategy",
        }
    }, {
        name: "DATA SOURCE",
        description: "The DATA SOURCE module can take data from other indicators, combine or modify them using mathematical operations before using them in the main CONDITION MANAGER module or any other module (even another DATA SOURCE) to create rules (RULE) or constants (CONSTANT).",
        tags: ["indicator", "ds", "data source", 'constant', 'cdzv', 'toolkit', 'cdzvmodules', 'pro_payed'],
        url: [
            "https://www.tradingview.com/script/TnIJdpjA-cm-i-data-source/",
        ],
        strategy: false,
        free: false,
        manual_url: {
            en: "https://docs.cdzv.com/code-zero-visual-trading_en/the-components/indicators/data-source",
            ua: "https://docs.cdzv.com/code-zero-visual-trading_ua/komponenti/indikatori/data-source",
            ru: "https://docs.cdzv.com/code-zero-visual-trading_ru/komponenty/indikatory/data-source",
        }
    }, {
        name: "IF-THEN-ELSE",
        description: "The IF-THEN-ELSE module-indicator is your assistant that works on the principle of 'if, then, else'.",
        tags: ["indicator", "if", "if-then-else", 'rule', 'cdzv', 'toolkit', 'cdzvmodules', 'not_payed', 'pro_payed'],
        url:
            [
                "https://www.tradingview.com/script/q9lhfgYs-CM-I-IF-THEN-ELSE/",
            ],
        strategy: false,
        free: true,
        manual_url:
            {
                en: "https://docs.cdzv.com/code-zero-visual-trading_en/the-components/indicators/if-then-else",
                ua: "https://docs.cdzv.com/code-zero-visual-trading_ua/komponenti/indikatori/if-then-else",
                ru: "https://docs.cdzv.com/code-zero-visual-trading_ru/komponenty/indikatory/if-then-else",
            }
    }, {
        name: "SWITCH",
        description:
            "SWITCH is an indicator that works like a channel switcher. Each channel, when activated, has its own data source, which will be the result when this channel is activated.",
        tags:
            ["indicator", "switch", 'rule', 'cdzv', 'toolkit', 'cdzvmodules', 'not_payed', 'pro_payed'],
        url:
            [
                "https://www.tradingview.com/script/ufuOW0bn-CM-I-SWITCH/",
            ],
        strategy: false,
        free: true,
        manual_url:
            {
                en: "https://docs.cdzv.com/code-zero-visual-trading_en/the-components/indicators/switch",
                ua: "https://docs.cdzv.com/code-zero-visual-trading_ua/komponenti/indikatori/switch",
                ru: "https://docs.cdzv.com/code-zero-visual-trading_ru/komponenty/indikatory/switch",
            }
    }, {
        name: "INTERVAL",
        description:
            "INTERVAL is an indicator that works as a timer, sending signals at a set frequency. It can be configured to send a signal every user-set number of bars, allowing for the automation of actions such as regular stock purchases or periodic checking of an asset's price.",
        tags:
            ["indicator", "interval", 'time', 'cdzv', 'toolkit', 'cdzvmodules', 'not_payed', 'pro_payed'],
        url:
            [
                "https://www.tradingview.com/script/wqxQ7pDB-CM-I-INTERVAL/",
            ],
        strategy: false,
        free: true,
        manual_url:
            {
                en: "https://docs.cdzv.com/code-zero-visual-trading_en/the-components/indicators/interval",
                ua: "https://docs.cdzv.com/code-zero-visual-trading_ua/komponenti/indikatori/interval",
                ru: "https://docs.cdzv.com/code-zero-visual-trading_ru/komponenty/indikatory/interval",
            }
    }, {
        name: "TIMER",
        description:
            "TIMER is an indicator that works as a timer that is activated by an input signal and sends a signal when the countdown of this timer is completed. It can be configured to send a signal after a user-defined number of bars, allowing you to automate actions such as closing a trade after a certain amount of time.",
        tags:
            ["indicator", "timer", 'time', 'cdzv', 'toolkit', 'cdzvmodules', 'not_payed', 'pro_payed'],
        url:
            [
                "https://www.tradingview.com/script/ZIA3tsua-CM-I-TIMER/",
            ],
        strategy: false,
        free: true,
        manual_url:
            {
                en: "https://docs.cdzv.com/code-zero-visual-trading_en/the-components/indicators/timer",
                ua: "https://docs.cdzv.com/code-zero-visual-trading_ua/komponenti/indikatori/timer",
                ru: "https://docs.cdzv.com/code-zero-visual-trading_ru/komponenty/indikatory/timer",
            }
    }, {
        name: "SESSIONS",
        description:
            "SESSIONS is an indicator that works like a clock that measures time intervals. It can be set to send signals at different time periods that can be customized by the user. For example, to trade on Tuesday and Thursday from 9 am to 11 am and then from 6 pm to midnight.",
        tags:
            ["indicator", "sessions", 'time', 'cdzv', 'toolkit', 'cdzvmodules', 'not_payed', 'pro_payed'],
        url:
            [
                "https://www.tradingview.com/script/8KK2C4rU-CM-I-SESSIONS/",
            ],
        strategy: false,
        free: true,
        manual_url:
            {
                en: "https://docs.cdzv.com/code-zero-visual-trading_en/the-components/indicators/sessions",
                ua: "https://docs.cdzv.com/code-zero-visual-trading_ua/komponenti/indikatori/sessions",
                ru: "https://docs.cdzv.com/code-zero-visual-trading_ru/komponenty/indikatory/sessions",
            }
    }, {
        name: "SOURCE MARKER [1-9]",
        description:
            "On the TradingView platform, there is a limitation that makes it difficult to visually distinguish identical indicators with different settings on the same chart, as they all appear identical when selecting a data source. Currently, the problem is solved using the SOURCE MARKER module, which serves as an intermediate chain for transferring data from one indicator to another.",
        tags:
            ["indicator", "source marker", 'label', 'marker', 'cdzv', 'toolkit', 'cdzvmodules', 'not_payed', 'pro_payed'],
        url:
            [
                "https://www.tradingview.com/script/Vjd26xE3-CM-I-SOURCE-MARKER-1/",
                "https://www.tradingview.com/script/a9gDVRyV-CM-I-SOURCE-MARKER-2/",
                "https://www.tradingview.com/script/zLtDnuAd-CM-I-SOURCE-MARKER-3/",
                "https://www.tradingview.com/script/fFQAfaTQ-CM-I-SOURCE-MARKER-4/",
                "https://www.tradingview.com/script/8wht0OX1-CM-I-SOURCE-MARKER-5/",
                "https://www.tradingview.com/script/Ph5e1Civ-CM-I-SOURCE-MARKER-6/",
                "https://www.tradingview.com/script/AzhtMFKd-CM-I-SOURCE-MARKER-7/",
                "https://www.tradingview.com/script/1Lxwuq87-CM-I-SOURCE-MARKER-8/",
                "https://www.tradingview.com/script/PHZlwLPr-CM-I-SOURCE-MARKER-9/"
            ],
        strategy: false,
        free: true,
        manual_url:
            {
                en: "https://docs.cdzv.com/code-zero-visual-trading_en/the-components/indicators/source-marker-1-9",
                ua: "https://docs.cdzv.com/code-zero-visual-trading_ua/komponenti/indikatori/source-marker-1-9",
                ru: "https://docs.cdzv.com/code-zero-visual-trading_ru/komponenty/indikatory/source-marker-1-9",
            }
    }, {
        name: "CLUSTER",
        description:
            "This indicator realizes the concept of \"cluster analysis\".\n" +
            "A cluster is the price of an asset in a certain period of time, in which transactions were made. The resulting volume of purchases and sales is indicated by a number inside the cluster. The bar of any TF contains, as a rule, several clusters. This allows to see in detail the volumes of purchases, sales and their balance in each separate bar, at each price level. This indicator provides information about the largest cluster on the bar, its price and volume.",
        tags:
            ["indicator", "cluster", 'volume', 'cdzv', 'toolkit', 'cdzvindicators'],
        url:
            [
                "https://www.tradingview.com/script/Zz3wrPLe-CM-I-CLUSTER/",
            ],
        strategy: false,
        free: true,
        manual_url:
            {
                en: "https://www.youtube.com/watch?v=RudTcgk9gTQ",
                ua: "",
                ru: "https://www.youtube.com/watch?v=6euKszTrLdI",
            }
    }, {
        name: "3Commas to MartinGrid Converter",
        description:
            "This indicator for calculating DCA parameters of 3Commas bot into Martin Grid.",
        tags:
            ["indicator", "3Commas", 'free', 'cdzv', 'martingrid', "cdzvindicators"],
        url:
            [
                "https://www.tradingview.com/script/cywS2RVS-CM-I-3Commas-to-MartinGrid-Converter/"
            ],
        strategy: false,
        free: true,
        manual_url:
            {
                en: "https://help.3commas.io/en/articles/3108940-advanced-dca-bot-interface-and-main-settings-beta-testing-off",
                ua: "",
                ru: "https://help.3commas.io/ru/articles/3108940-интерфейс-продвинутого-dca-бота-и-его-основные-параметры",
            }
    }, {
        name: "Channels Monster",
        description:
            "This is a universal indicator that allows you to choose a channel for any asset for channel trading.",
        tags:
            ["indicator", "channels", 'channel', 'cdzvindicators'],
        url:
            [
                "https://www.tradingview.com/script/SC6NOQeA-CM-I-Channels-Monster/",
            ],
        strategy: false,
        free: true,
        manual_url:
            {
                en: "",
                ua: "",
                ru: "",
            }
    }, {
        name: "Wick Imbalance",
        description:
            "This indicator shows the outweighing of market interest on each bar using the size of the candle wicks.",
        tags:
            ["indicator", "wick imbalance", 'cdzvindicators'],
        url:
            [
                "https://www.tradingview.com/script/xNlZ9xs5-CM-I-Wick-imbalance/",
            ],
        strategy: false,
        free: true,
        manual_url:
            {
                en: "",
                ua: "",
                ru: "",
            }
    }, {
        name: "Amazing Fractals",
        description:
            "This indicator can be used for more intelligent search of fractals with volume filtering, refining the size of price movement between these fractals.",
        tags:
            ["indicator", "fractals", 'levels', 'free', 'cdzvindicators'],
        url:
            [
                "https://www.tradingview.com/script/kkz3Efw1-CM-I-Amazing-Fractals/"
            ],
        strategy: false,
        free: true,
        manual_url:
            {
                en: "https://www.tradingview.com/script/kkz3Efw1-CM-I-Amazing-Fractals/",
                ua: "",
                ru: "",
            }
    }, {
        name: "Smoothed Heiken Ashi - [SHA]",
        description:
            "This indicator is modified for use with CDZV. It is a Heiken Ashi candle overlay. It has a selectable moving average before HA calculation and another after HA calculation, to smooth original Heiken Ashi candles.",
        tags:
            ["indicator", "smoothed", "heiken", "ashi", 'sha', 'free', 'modified'],
        url:
            [
                "https://www.tradingview.com/script/OYFce4zE-CM-I-Smoothed-Heiken/"
            ],
        strategy: false,
        free: true,
        manual_url:
            {
                en: "https://www.tradingview.com/v/pjl3mIvc/",
                ua: "",
                ru: "",
            }
    }, {
        name: "Relative Strength Index Any Symbol - [RSI AnyS]",
        description:
            "This is a modification of the standard RSI, which can be plotted on a chart of an asset by specifying a different asset and a different timeframe.",
        tags:
            ["indicator", "rsi", 'free', 'modified'],
        url:
            [
                "https://www.tradingview.com/script/A5wtbQtp-CM-I-Relative-Strength-Index-Any-Symbol/"
            ],
        strategy: false,
        free: true,
        manual_url:
            {
                en: "https://www.tradingview.com/scripts/relativestrengthindex/?solution=43000502338",
                ua: "",
                ru: "https://ru.tradingview.com/scripts/relativestrengthindex/?solution=43000502338",
            }
    }, {
        name: "Divergence for Many Indicators",
        description:
            "This is a modification of \"Divergence for Many Indicators v4\" for use with CDZV. This is a universal indicator that allows you to search for discrepancies across multiple indicators. This version has been optimized and rewritten for Pine V5.",
        tags:
            ["indicator", "divergence", 'free', 'modified'],
        url:
            [
                "https://www.tradingview.com/script/7yDLC9yb-CM-I-Divergence-for-Many-Indicators/"
            ],
        strategy: false,
        free: true,
        manual_url:
            {
                en: "https://www.tradingview.com/script/n8AGnIZd-Divergence-for-Many-Indicators-v4/",
                ua: "",
                ru: "",
            }
    }, {
        name: "Smart Money Concepts [LuxAlgo]",
        description:
            "This indicator is modified for use with CDZV. Smart Money Concepts.",
        tags:
            ["indicator", "smart", "smart money", 'free', 'luxalgo', 'modified'],
        url:
            [
                "https://www.tradingview.com/script/ohXovqIq-CM-I-Smart-Money-Concepts-LuxAlgo/"
            ],
        strategy: false,
        free: true,
        manual_url:
            {
                en: "https://www.tradingview.com/script/CnB3fSph-Smart-Money-Concepts-LuxAlgo/",
                ua: "",
                ru: "",
            }
    }, {
        name: "Pivots Points",
        description:
            "This is a technical indicator that is used to determine the levels at which price may face support or resistance. The Pivot Points indicator consists of a pivot point (PP) level and several support (S) and resistance (R) levels.",
        tags:
            ["indicator", "pivot", 'free', 'cdzvindicators'],
        url:
            [
                "https://www.tradingview.com/script/l04TZZeP-CM-I-Pivots-Points/"
            ],
        strategy: false,
        free: true,
        manual_url:
            {
                en: "https://www.tradingview.com/chart/AYBTQlSc/?solution=43000521824",
                ua: "",
                ru: "",
            }
    }, {
        name: "Williams Fractals",
        description:
            "This indicator is modified for use with CDZV. The Williams Fractal indicator aims to detect reversal points through highs and lows. It’s known as being one of the first indicators to use fractals and bring it into the trading mainstream. Generally, the indicator is formed by five candlesticks or bars on the price chart and it’s shown with an up or down arrow to signal its high or low status.",
        tags:
            ["indicator", "williams fractals", 'levels', 'free', 'modified'],
        url:
            [
                "https://www.tradingview.com/script/1yk1pUCb-CM-I-Williams-Fractals/"
            ],
        strategy: false,
        free: true,
        manual_url:
            {
                en: "https://www.tradingview.com/chart/AYBTQlSc/?solution=43000591663",
                ua: "",
                ru: "",
            }
    }, {
        name: "Machine Learning: Lorentzian Classification",
        description:
            "This indicator is modified for use with CDZV. A Lorentzian Distance Classifier (LDC) is a Machine Learning classification algorithm capable of categorizing historical data from a multi-dimensional feature space. This indicator demonstrates how Lorentzian Classification can also be used to predict the direction of future price movements when used as the distance metric for a novel implementation of an Approximate Nearest Neighbors (ANN) algorithm.",
        tags:
            ["indicator", "machine learning", 'ai', 'ml', 'free', 'modified'],
        url:
            [
                "https://www.tradingview.com/script/1wnxHq50-CM-I-Machine-Learning-Lorentzian-Classification/"
            ],
        strategy: false,
        free: true,
        manual_url:
            {
                en: "https://www.tradingview.com/script/WhBzgfDu-Machine-Learning-Lorentzian-Classification/",
                ua: "",
                ru: "",
            }
    }, {
        name: "Machine Learning: kNN-based Strategy",
        description:
            "This indicator is modified for use with CDZV. This script is a k-Nearest Neighbors (kNN) based strategy that uses a machine learning algorithm to predict the direction of future price movements. The script is designed to be used with FX and Crypto pairs.",
        tags:
            ["indicator", "machine learning", 'ai', 'ml', 'free', 'knn', 'modified'],
        url:
            [
                "https://www.tradingview.com/script/hTFoFvi3-CM-I-Machine-Learning-kNN-based-Strategy/"
            ],
        strategy: false,
        free: true,
        manual_url:
            {
                en: "https://ru.tradingview.com/script/8cAQrM6a-Machine-Learning-kNN-based-Strategy-update/",
                ua: "",
                ru: "",
            }
    }, {
        name: "Calculate moving price",
        description:
            "This is an indicator that allows you to find the optimal amount of price movement from pullback to pullback with a certain probability. The indicator calculates the price movement from fractal to fractal (average growth, average decline, maximum movements)",
        tags:
            ["indicator", "calculate", "moving price", 'free', 'cdzvindicators'],
        url:
            [
                "https://www.tradingview.com/script/UdDWtKO7-CM-I-Calculate-moving-price/"
            ],
        strategy: false,
        free: true,
        manual_url:
            {
                en: "",
                ua: "",
                ru: "",
            }
    }, {
        name: "MA Ribbon",
        description:
            "Moving Average Ribbon is an indicator that displays up to 8 EMA on your chart. Moving averages of varying lengths can be used to compare long-term and short-term trends and attempt to predict possible chart movements based on its past performance.",
        tags:
            ["indicator", "cdzvindicators", "ema", 'free'],
        url:
            [
                "https://www.tradingview.com/script/SYi7hs6c-CM-I-EMA-Ribbon/"
            ],
        strategy: false,
        free: true,
        manual_url:
            {
                en: "",
                ua: "",
                ru: "",
            }
    }, {
        name: "TDFI - Trend Direction Force Index",
        description:
            "This indicator is modified for use with CDZV. Trend Direction Force Index (TDFI) is a technical indicator that helps to determine the trend direction in the market. It is based on the comparison of the current price with its previous values and trading volume.",
        tags:
            ["indicator", "tdfi", 'trend', "index", 'free', 'modified'],
        url:
            [
                "https://www.tradingview.com/script/knwfUoRu-CM-I-Trend-Direction-Force-Index/"
            ],
        strategy: false,
        free: true,
        manual_url:
            {
                en: "https://www.tradingview.com/v/4o8gouR8/",
                ua: "",
                ru: "",
            }
    }, {
        name: "Heiken Ashi Candle Overlay",
        description:
            "This indicator is modified for use with CDZV. For Heiken Ashi lovers, this script does several things. It gives you both bars and hollow candles with Heikin Ashi values - something TV does not currently support.",
        tags:
            ["indicator", "smoothed", "heiken", "ashi", 'sha', 'overlay', 'free', 'modified'],
        url:
            [
                "https://www.tradingview.com/script/nJMCnjI4-CM-I-Heiken-Ashi-Candle-Overlay/"
            ],
        strategy: false,
        free: true,
        manual_url:
            {
                en: "https://www.tradingview.com/v/TA4mVPD8/",
                ua: "",
                ru: "",
            }
    }, {
        name: "Engulfing Candle Indicator",
        description:
            "This indicator is modified for use with CDZV. This script shows you where a candle is either bullish or bearish engulfing the previous candle.",
        tags:
            ["indicator", "Engulfing", 'candle', 'free', 'modified'],
        url:
            [
                "https://www.tradingview.com/script/T6EavLgp-CM-I-Engulfing-Candle-Indicator/"
            ],
        strategy: false,
        free: true,
        manual_url:
            {
                en: "https://tradingview.com/script/YrudGA12-Engulfing-Candle-Indicator/",
                ua: "",
                ru: "",
            }
    }, {
        name: "LordPepe Stochastic Signals",
        description:
            "This indicator is modified for use with CDZV. Basic buy/sell indicator to accumulate along a downtrend and release your stack during the uptrend and oversold levels of the stochastic. The buys should be used to stack, and sells indicate levels of profit taking, they do not signal a long term reversal, only < 25% of stack should be released on \"OB\" signals.",
        tags:
            ["indicator", "exhaustion", 'sha', 'free', 'LordPepe', 'Stochastic', 'lordpepe', 'modified'],
        url:
            [
                "https://www.tradingview.com/script/dciIZbzP-CM-I-LordPepe-Stochastic-Signals/"
            ],
        strategy: false,
        free: true,
        manual_url:
            {
                en: "https://www.tradingview.com/script/16Da1RcQ-LordPepe-Stochastic-Signals/",
                ua: "",
                ru: "",
            }
    }, {
        name: "OBV MACD Indicator",
        description:
            "This indicator is modified for use with CDZV. This script is an improvement on MACD: it is faster and more accurate, with less lag, and is primarily based on OBV as the main source.",
        tags:
            ["indicator", "obv", 'macd', 'free', 'modified'],
        url:
            [
                "https://www.tradingview.com/script/jhqqUM3E-CM-I-OBV-MACD-Indicator/"
            ],
        strategy: false,
        free: true,
        manual_url:
            {
                en: "https://www.tradingview.com/v/jXvqrU4q/",
                ua: "",
                ru: "",
            }
    }, {
        name: "Flat & Trend MACD",
        description:
            "This indicator is modified for use with CDZV. The MACD indicator with trend interpretation and flat zones on top of the chart.",
        tags:
            ["indicator", "obv", 'macd', 'trend', 'flat', 'free', 'modified'],
        url:
            [
                "https://www.tradingview.com/script/SBtlg7I9-CM-I-Flat-Trend-MACD/"
            ],
        strategy: false,
        free: true,
        manual_url:
            {
                en: "https://www.tradingview.com/v/0cHW3eZf/",
                ua: "",
                ru: "",
            }
    }, {
        name: "SlhTgh MACD ADX",
        description:
            "This indicator is modified for use with CDZV. Signals provided by MACD + ADX indicators.",
        tags:
            ["indicator", "adx", 'macd', 'SlhTgh', 'free', 'modified'],
        url:
            [
                "https://www.tradingview.com/script/T6qTOLYB-CM-I-SlhTgh-MACD-ADX/"
            ],
        strategy: false,
        free: true,
        manual_url:
            {
                en: "https://www.tradingview.com/v/6wmOjIVG/",
                ua: "",
                ru: "",
            }
    }, {
        name: "T3 @DaviddTech",
        description:
            "This indicator is modified for use with CDZV. Indicator used to expose the video strategy.",
        tags:
            ["indicator", "t3", 'DaviddTech', 'free', 'modified'],
        url:
            [
                "https://www.tradingview.com/script/CaFByaCe-CM-I-T3-DaviddTech/"
            ],
        strategy: false,
        free: true,
        manual_url:
            {
                en: "",
                ua: "",
                ru: "",
            }
    }, {
        name: "Any Ticker Price",
        description:
            "This indicator allows you to get OHLCV from any symbols and any timeframes.",
        tags:
            ["indicator", 'free', 'cdzvindicators', 'ohlcv'],
        url:
            [
                "https://www.tradingview.com/script/hbDQqKeC-CM-I-Any-Ticker-Price/"
            ],
        strategy: false,
        free: true,
        manual_url:
            {
                en: "",
                ua: "",
                ru: "",
            }
    }, {
        name: "Magic xMA",
        description:
            "This indicator allows you to get: any Moving Averages - any symbols - any timeframes.",
        tags:
            ["indicator", 'free', 'cdzvindicators', 'ema', 'sma', 'hma', 'wma'],
        url:
            [
                "https://www.tradingview.com/script/VESyuSvY-CM-I-Magic-xMA/"
            ],
        strategy: false,
        free: true,
        manual_url:
            {
                en: "",
                ua: "",
                ru: "",
            }
    }, {
        name: "TMA Overlay",
        description:
            "This indicator is modified for use with Condition Manager. This indicator help identify momentum by offering a visual representation of engulfing candlestick patters overlayed with smoothed moving averages.",
        tags:
            ["indicator", 'free', 'ema', 'sma', 'hma', 'wma', 'modified'],
        url:
            [
                "https://www.tradingview.com/script/sgDvUV28-CM-I-TMA-Overlay/"
            ],
        strategy: false,
        free: true,
        manual_url:
            {
                en: "https://www.tradingview.com/script/zX3fvduH-TMA-Overlay/",
                ua: "",
                ru: "",
            }
    }, {
        name: "Schaff Trend Cycle (STC) [Scrudge]",
        description:
            "This indicator is modified for use with Condition Manager.",
        tags:
            ["indicator", 'free', 'stc', 'modified'],
        url:
            [
                "https://www.tradingview.com/script/JUK476pQ-CM-I-Schaff-Trend-Cycle-STC-Scrudge/"
            ],
        strategy: false,
        free: true,
        manual_url:
            {
                en: "",
                ua: "",
                ru: "",
            }
    }, {
        name: "SuperTrend [Oscillator]",
        description:
            "This indicator is modified for use with Condition Manager.",
        tags:
            ["indicator", 'free', 'supertrend', 'modified'],
        url:
            [
                "https://www.tradingview.com/script/IDqDtmmU-CM-I-Supertrend/"
            ],
        strategy: false,
        free: true,
        manual_url:
            {
                en: "https://www.tradingview.com/chart/?solution=43000634738",
                ua: "",
                ru: "",
            }
    }, {
        name: "Volume Bar Breakout and Breakdown Indicator",
        description:
            "This indicator is modified for use with Condition Manager.",
        tags:
            ["indicator", 'free', 'volume', 'modified'],
        url:
            [
                "https://www.tradingview.com/script/ZSALgyVp-CM-I-Volume-Bar-Breakout-and-Breakdown-Indicator/"
            ],
        strategy: false,
        free: true,
        manual_url:
            {
                en: "https://www.tradingview.com/script/V2cjsLhI-Volume-Bar-Breakout-and-Breakdown-Indicator/",
                ua: "",
                ru: "",
            }
    }, {
        name: "Chandelier Exit",
        description:
            "This indicator is modified for use with Condition Manager.",
        tags:
            ["indicator", 'free', 'Chandelier', 'atr', 'modified'],
        url:
            [
                "https://www.tradingview.com/script/g3lWAyRX-CM-I-Chandelier-Exit/"
            ],
        strategy: false,
        free: true,
        manual_url:
            {
                en: "https://www.tradingview.com/script/AqXxNS7j-Chandelier-Exit/",
                ua: "",
                ru: "",
            }
    }, {
        name: "ATR Percent Rank ANY",
        description:
            "This indicator allows you to get ATR Percent Rank from any symbols and any timeframes.",
        tags:
            ["indicator", 'free', 'modified', 'atr', 'natr', 'percent'],
        url:
            [
                "https://www.tradingview.com/script/DCiQQpPB-ATR-Percent-Rank-ANY/"
            ],
        strategy: false,
        free: true,
        manual_url:
            {
                en: "",
                ua: "",
                ru: "",
            }
    }, {
        name: "HighestHigh Any TF",
        description:
            "This indicator allows you to get Highest High levels from any timeframes (up to 6 levels).",
        tags:
            ["indicator", 'free', 'cdzvindicators', 'level', 'high'],
        url:
            [
                "https://www.tradingview.com/script/liiVrDpK-HighestHigh-Any-TF/"
            ],
        strategy: false,
        free: true,
        manual_url:
            {
                en: "",
                ua: "",
                ru: "",
            }
    }, {
        name: "LowestLow Any TF",
        description:
            "This indicator allows you to get Lowest Low levels from any timeframes (up to 6 levels).",
        tags:
            ["indicator", 'free', 'cdzvindicators', 'level', 'low'],
        url:
            [
                "https://www.tradingview.com/script/ZNPYthFL-LowestLow-Any-TF/"
            ],
        strategy: false,
        free: true,
        manual_url:
            {
                en: "",
                ua: "",
                ru: "",
            }
    }
];

export default cdzv_modules;