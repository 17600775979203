import {Button, Layout, Modal, Space} from "antd";
import {Header, Content, Footer} from "antd/es/layout/layout";
import HeaderMenu from "../../components/HeaderMenu";
// import LogoImage from "../../../assets/images/logo_922_922.png";
import {mobXStore} from "../../../store";
import {Link, useSearchParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import Profile from "../Profile";
import Strategy from "../Strategy";
import Indicators from "../Indicators";
import Connectors from "../Connectors";

import "./index.css";
import Gateways from "../Gateways";
import BotsExchange from "../BotsExchange";
import Webhooks from "../Webhooks";
import {MenuFoldOutlined, MenuUnfoldOutlined} from "@ant-design/icons";
import BotsWebhook from "../BotsWebhook";
import {showModalDemoWarning} from "../../../Helpers";
import Cookies from "js-cookie";

const {Sider} = Layout;

function Index() {

    const [queryParameters] = useSearchParams()

    const [openModal, setOpenModal] = useState(false);
    const [collapsed, setCollapsed] = useState(false);

    const [demoMode, setDemoMode] = useState(false);

    const [currentMenu, setCurrentMenu] = useState('indicators');

    useEffect(() => {
        if (queryParameters.has('updateprofile')) {
            setCurrentMenu('profile');
        }
    }, [queryParameters]);

    useEffect(() => {
        setDemoMode(mobXStore.getKey('demo_mode'));

        if (parseInt(mobXStore.getKey('addStrategyID')) > 0) {
            setCurrentMenu('bots');
        }
    }, []);

    const onMenuChange = (key) => {
        setCurrentMenu(key)

        if (key === 'logout') {
            setOpenModal(true);
        } else if (key === 'profile') {
            if (demoMode) {
                setCurrentMenu('strategy');
                showModalDemoWarning();
                return;
            }
        }
        if (key === 'support') {
            if (demoMode) {
                setCurrentMenu('strategy');
                showModalDemoWarning();
                return;
            }
            setCurrentMenu('profile');
            window.open('https://discord.com/channels/1203072937335455784/1223217249918259200', '_blank');
            window.location = '/?updateprofile';
        } else if (key === 'ai-anysearchbot') {
            setCurrentMenu('ai-anysearchbot');
            window.open('https://t.me/AnySearchBot_bot', '_blank');
            if (demoMode) {
                setCurrentMenu('strategy');
                // showModalDemoWarning();
                return;
            }
            window.location = '/?updateprofile';
        } else if (key === 'academy') {
            setCurrentMenu('academy');
            window.open('https://cdzv.com/uta_ru/', '_blank');
            if (demoMode) {
                setCurrentMenu('strategy');
                // showModalDemoWarning();
                return;
            }
            window.location = '/?updateprofile';
        }
    }

    const LogOut = () => {
        Cookies.set('ACCESS_TOKEN', '');
        Cookies.remove('ACCESS_TOKEN');
        mobXStore.setKey('login', false);
        mobXStore.setKey('demo_mode', false);
        mobXStore.setKey('free_mode', false);
        mobXStore.delete();
    };

    return (
        <Layout theme={"light"} className={"Layout"}>
            <Modal
                title="Confirm"
                open={openModal}
                onOk={LogOut}
                onCancel={() => {
                    setOpenModal(false);
                    window.location = '/?updateprofile';
                }}
                okText="Ok"
                cancelText="Cancel"
            >
                Do you really want to log out?
            </Modal>
            <Sider theme={"light"}
                   trigger={null}
                   collapsible
                   collapsed={collapsed}
            >
                <Header
                    theme={"light"}
                    style={{
                        // color: '#030342',
                        background: '#ffffff',
                        // paddingLeft: "11px",
                        padding: 0,
                    }}
                >
                    <Button
                        type="text"
                        icon={collapsed ? <MenuUnfoldOutlined/> : <MenuFoldOutlined/>}
                        onClick={() => setCollapsed(!collapsed)}
                        style={{
                            color: '#030342',
                            // color: '#f8fffd',
                            marginLeft: 8,
                            width: 64,
                            height: 64,
                        }}
                    />
                </Header>
                {/*<Image src={LogoImage} width={50} height={50} preview={false}/>*/}
                <HeaderMenu
                    defaultKey={currentMenu}
                    onMenuChange={onMenuChange}
                />
            </Sider>
            <Layout>
                <Content className={"Content"}>
                    {
                        (currentMenu === 'profile' && <Profile/>)
                    }
                    {
                        (currentMenu === 'indicators' && <Indicators/>)
                    }
                    {
                        (currentMenu === 'strategy' && <Strategy changeMenu={setCurrentMenu}/>)
                    }
                    {
                        (currentMenu === 'gateways' && <Gateways/>)
                    }
                    {
                        (currentMenu === 'connectors' && <Connectors/>)
                    }
                    {
                        (currentMenu === 'webhooks' && <Webhooks/>)
                    }
                    {
                        (currentMenu === 'bots' && <BotsExchange/>)
                    }
                    {
                        (currentMenu === 'bots_webhook' && <BotsWebhook/>)
                    }
                </Content>
                <Footer className={"Footer"}>
                    <Space size={"large"} wrap>
                        <Link to={'https://discord.com/channels/1203072937335455784/1203075900921483324'}
                              target="_blank"><strong>CDZV Community [Discord]</strong></Link>
                        {/*<Link to={'https://cdzv.com'} target="_blank">www.cdzv.com</Link>*/}
                        <Link to={'https://docs.cdzv.com/code-zero-visual-trading_en'} target="_blank">Docs [EN]</Link>
                        <Link to={'https://docs.cdzv.com/code-zero-visual-trading_ua'} target="_blank">Docs [UA]</Link>
                        <Link to={'https://docs.cdzv.com/code-zero-visual-trading_ru'} target="_blank">Docs [RU]</Link>
                        {/*<Link to={'https://docs.cdzv.com'} target="_blank">Blog</Link>*/}
                        <Link to={'https://www.youtube.com/@cdzv_info'} target="_blank">Youtube [EN]</Link>
                        <Link to={'https://www.youtube.com/@CDZV_R'} target="_blank">Youtube [RU]</Link>
                        <Link to={'https://www.reddit.com/r/CodeZeroVisualTrading/'} target="_blank">Reddit</Link>
                        <Link to={'mailto:support@cdzv.com'}>support@cdzv.com</Link>
                    </Space>
                </Footer>
            </Layout>
        </Layout>
    );
}

export default Index;