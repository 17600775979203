import {Image, Menu, Tooltip} from 'antd';
import React, {useEffect, useState} from "react";
import {
    AndroidOutlined,
    ApartmentOutlined,
    ApiOutlined,
    AppstoreOutlined,
    ControlOutlined,
    DiscordOutlined, DollarOutlined, FullscreenExitOutlined,
    GatewayOutlined,
    LogoutOutlined,
    QuestionCircleOutlined,
    RobotOutlined,
    SettingOutlined, SlidersOutlined
} from "@ant-design/icons";

import './index.css';

const items = [
    {
        label: 'CDZV Toolkit',
        key: 'indicators',
        icon: <SlidersOutlined/>,
        disabled: false,
    },
    {
        // title: (<Tooltip>Ready-made strategies</Tooltip>),
        label: 'Ready strategies',
        key: 'strategy',
        icon: <AppstoreOutlined/>,
        disabled: false,
    },
    {
        label: 'Exchanges',
        key: 'connectors',
        icon: <ControlOutlined/>,
        disabled: false,

    },
    {
        label: 'Copy Trades',
        key: 'SubMenuCT',
        icon: <AndroidOutlined/>,
        disabled: false,
        children: [
            {
                label: 'to Exchange',
                key: 'bots',
                icon: <ApiOutlined/>,
                disabled: false
            },
            {
                label: 'to Webhook',
                key: 'bots_webhook',
                icon: <ApartmentOutlined/>,
                disabled: false
            },
        ]
    },
    {
        label: 'Webhooks',
        key: 'SubMenuWH',
        icon: <ApartmentOutlined/>,
        disabled: false,
        children: [
            {
                label: 'CDZV',
                key: 'webhooks',
                icon: <FullscreenExitOutlined/>,
                disabled: false
            },
            {
                label: '3rd party',
                key: 'gateways',
                icon: <GatewayOutlined/>,
                disabled: false,
            }]
    },
    {
        label: 'Profile',
        key: 'profile',
        icon: <SettingOutlined/>,
        disabled: false
    },
    {
        label: 'Support',
        key: 'support',
        icon: <DiscordOutlined/>,
        disabled: false
    },
    {
        label: "Trading courses",
        icon: <DollarOutlined/>,
        key: 'academy',
        disabled: false
    },
    {
        label: (<>
            <Tooltip placement="right" overlayInnerStyle={{
                width: '500px',
                // backgroundColor: '#efefefef',
                // color: 'black',
            }}
                     title={<div>
                         <h3>
                             How to get full access:<br/><br/>

                             1. Follow the link and launch the bot.<br/>
                             2. Call the <span style={{color: 'red'}}>/info</span> command<br/>
                             3. The ChatID will be listed at the bottom of the message.<br/>
                             4. Enter the ChatID in the user panel under Profile: @AnySearchBot_bot
                             ChatId.<br/><br/>

                             Enjoy!
                         </h3>
                         <Image width={480}
                                src={"https://cdzv.com/strategy_img/Telegram_2024-06-02_19-02-16.png"}/>
                     </div>}>
                Financial AI <QuestionCircleOutlined/>
            </Tooltip>
        </>),
        key: 'ai-anysearchbot',
        icon: (<>
            <RobotOutlined/>
        </>),
        disabled: false
    },
    // {
    //     label: "",
    //     key: 'empty1',
    //     disabled: true
    // },
    {
        label: "LogOut",
        icon: <LogoutOutlined/>,
        key: 'logout',
        disabled: false
    },
];

const HeaderMenu = (props) => {
    const {onMenuChange, defaultKey} = props;
    const [current, setCurrent] = useState('indicators');

    useEffect(() => {
        setCurrent(defaultKey)
    }, [defaultKey]);

    const onClick = (e) => {
        setCurrent(e.key);
        onMenuChange(e.key)
    };

    return (
        <Menu
            theme={"light"}
            onClick={onClick}
            // disabledOverflow={true}
            style={{fontFamily: 'Terminal, monospace', fontSize: '13px', fontWeight: '600'}}
            selectedKeys={[current]}
            mode="inline"
            items={items}
            // defaultOpenKeys={['SubMenuCT']}
            // className={"menu"}
        />
    );
}

export default HeaderMenu;
