import React, {useEffect, useRef, useState} from 'react';
import {
    Button,
    Card,
    Col,
    ConfigProvider,
    Image,
    Input,
    Modal,
    Popconfirm,
    Row,
    Select,
    Space,
    Switch,
    Table,
    Tooltip
} from 'antd';
import {Content} from "antd/es/layout/layout";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {
    CaretDownOutlined,
    CaretUpOutlined,
    ClearOutlined,
    CloseOutlined,
    CopyOutlined,
    DeleteOutlined,
    EditOutlined,
    FullscreenExitOutlined,
    InfoCircleOutlined,
    SearchOutlined
} from "@ant-design/icons";
import API from "../../../api/user";
import exchanges from "../Connectors/exchanges";
import {showModalDemoWarning} from "../../../Helpers";
import {mobXStore} from "../../../store";

const filterOption = (input, option) =>
    (option?.search_label ?? '').toLowerCase().includes(input.toLowerCase());

// Webhooks component
function Webhooks(props) {

    const apiUser = new API();

    const [demoMode, setDemoMode] = useState(mobXStore.getKey('demo_mode'));

    const [showAddWebhookModal, setShowAddWebhookModal] = useState(false);
    const [showEditWebhookModal, setShowEditWebhookModal] = useState(false);

    const [showFuturesInputFields, setShowFuturesInputFields] = useState(false);

    const [editRecordId, setEditRecordId] = useState(0);
    const [inputWHName, setInputWHName] = useState('');
    const [inputExchangeId, setInputExchangeId] = useState(0);
    const [inputOrderAmount, setInputOrderAmount] = useState(0);
    const [inputLeverage, setInputLeverage] = useState(1);
    const [inputMarginMode, setInputMarginMode] = useState('cross');

    const [dataSourceExchange, setDataSourceExchange] = useState([]);
    const [dataSource, setDataSource] = useState([]);
    const [loading, setLoading] = useState(false);

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const sortIcon = (sorted) => {
        // alert(JSON.stringify(sorted))
        if (sorted['sortOrder'] === 'descend') {
            return (<CaretDownOutlined
                style={{
                    color: '#1677ff'
                }}
            />)
        } else if (sorted['sortOrder'] === 'ascend') {
            return (<CaretUpOutlined
                style={{
                    color: '#1677ff'
                }}
            />)
        } else {
            return (<FullscreenExitOutlined
                style={{
                    color: 'white',
                }}
            />)
        }
    }

    const getColumnSearchProps = (dataIndex, tooltip_text = '') => ({
        filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters, close}) => (
            <div
                style={{
                    // backgroundColor: '#0f0fa8',
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Space direction={'horizontal'}>
                    <Input
                        ref={searchInput}
                        placeholder={tooltip_text}
                        value={selectedKeys[0]}
                        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{
                            marginBottom: 8,
                            width: 200,
                            display: 'block',
                        }}
                    />
                    <Button
                        type="button"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        <CloseOutlined/>
                    </Button>
                </Space>
                <br/>
                <Space direction={'horizontal'}>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined/>}
                        size="small"
                        style={{
                            width: 115,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && (handleReset(clearFilters) || handleSearch(selectedKeys, confirm, dataIndex) || close())}
                        size="small"
                        style={{
                            width: 115,
                        }}
                    >
                        <ClearOutlined/> Reset
                    </Button>
                    {/*<Button*/}
                    {/*    type="link"*/}
                    {/*    size="small"*/}
                    {/*    onClick={() => {*/}
                    {/*        confirm({*/}
                    {/*            closeDropdown: false,*/}
                    {/*        });*/}
                    {/*        setSearchText(selectedKeys[0]);*/}
                    {/*        setSearchedColumn(dataIndex);*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    Filter*/}
                    {/*</Button>*/}
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#f6adb5' : 'white',
                }}
            />
        ),
        sortIcon: (sorted) => sortIcon(sorted),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        // render: (text) =>
        //     searchedColumn === dataIndex ? (
        //         <Highlighter
        //             highlightStyle={{
        //                 backgroundColor: '#ffc069',
        //                 padding: 0,
        //             }}
        //             searchWords={[searchText]}
        //             autoEscape
        //             textToHighlight={text ? text.toString() : ''}
        //         />
        //     ) : (
        //         text
        //     ),
    });

    useEffect(() => {
        setDemoMode(mobXStore.getKey('demo_mode'));
        getUserExchange();
        getUserWebhooks();
    }, []);

    function getUserExchange() {
        setLoading(true);
        apiUser.getUserExchange().then((response) => {
            if (response.result) {
                // console.log(response);
                let res = JSON.parse(response.data);
                res.forEach((item) => {
                    let exchange = exchanges.find((exchange) => exchange.ccxt_name === item.exchange);
                    item.exchange_name = exchange.name;
                    item.logo = exchange.logo;
                });
                setDataSourceExchange(res);
                // console.log(response.data);
            } else {
                // console.log(response.error);
                apiUser.logout();
            }
            setLoading(false);
        });
    }

    function getUserWebhooks() {
        setLoading(true);
        apiUser.getUsersWebhooks({demo: demoMode}).then((response) => {
            if (response.result) {
                // console.log(response);
                let res = JSON.parse(response.data);
                res.forEach((item) => {
                    let exchange = exchanges.find((exchange) => exchange.ccxt_name === item.exchange);
                    // item.exchange_name = exchange.name;
                    item.logo = exchange.logo;
                });
                setDataSource(res);
                // console.log(response.data);
            } else {
                // console.log(response.error);
                apiUser.logout();
            }
            setLoading(false);
        });
    }

    function handleChangeActive(key, is_active) {
        if (demoMode) {
            showModalDemoWarning();
            return;
        }

        setLoading(true);
        apiUser.changeActiveWebhookConnector({id: key, is_active: is_active}).then((response) => {
            if (response.result) {
                getUserWebhooks();
                // Modal.success({
                //     title: 'Success',
                //     content: 'Webhook status changed successfully',
                // });
            } else {
                // console.log(response.error);
                Modal.error({
                    title: 'Error',
                    content: 'Webhook status change error: ' + response.error,
                });
            }
            setLoading(false);
        });
    }

    function handleDeleteWebhook(key) {
        if (demoMode) {
            showModalDemoWarning();
            return;
        }

        setLoading(true);
        apiUser.deleteUserWebhook({id: key}).then((response) => {
            if (response.result) {
                getUserWebhooks();
                Modal.success({
                    title: 'Success',
                    content: 'Webhook deleted successfully',
                });
            } else {
                // console.log(response.error);
                Modal.error({
                    title: 'Error',
                    content: 'Webhook delete error: ' + response.error,
                });
            }
            setLoading(false);
        });
    };

    // Show modal for edit Webhook properties
    function handleEdit(record) {
        setEditRecordId(record.key);
        setInputWHName(record.name);
        setInputExchangeId(record.exchange_key);
        setInputOrderAmount(record.order_amount_usd);
        setInputLeverage(record.leverage);
        setInputMarginMode(record.margin_mode);

        if (record.market === 'future') {
            setShowFuturesInputFields(true);
        } else {
            setShowFuturesInputFields(false);
        }

        setShowEditWebhookModal(true);
    }

    // saveNewWebhookToDB
    function saveNewWebhookToDB() {
        if (demoMode) {
            showModalDemoWarning();
            return;
        }

        if (!inputExchangeId) {
            Modal.error({
                title: 'Error',
                content: 'Please select exchange',
            });
            return;
        }

        if (!inputWHName) {
            Modal.error({
                title: 'Error',
                content: 'Please enter webhook name',
            });
            return;
        }

        if (!inputOrderAmount || inputOrderAmount <= 0) {
            Modal.error({
                title: 'Error',
                content: 'Please enter order amount',
            });
            return;
        }

        setShowAddWebhookModal(false);

        const data = {
            exchange_connectors_id: inputExchangeId,
            name: inputWHName,
            order_amount_usd: inputOrderAmount,
            leverage: inputLeverage,
            margin_mode: inputMarginMode
        }

        apiUser.createNewWebhook(data).then((response) => {
            if (response.result) {
                getUserWebhooks();
                Modal.success({
                    title: 'Success',
                    content: 'Webhook created successfully',
                });
            } else {
                // console.log(response.error);
                Modal.error({
                    title: 'Error',
                    content: 'Webhook create error: ' + response.error,
                });
            }
            setLoading(false);
        });
    }

    // editWebhookToDB
    function editWebhookToDB() {
        if (demoMode) {
            showModalDemoWarning();
            return;
        }

        setShowEditWebhookModal(false);

        const data = {
            id: editRecordId,
            name: inputWHName,
            order_amount_usd: inputOrderAmount,
            leverage: inputLeverage,
            margin_mode: inputMarginMode
        }

        apiUser.updateWebhook(data).then((response) => {
            if (response.result) {
                getUserWebhooks();
                Modal.success({
                    title: 'Success',
                    content: 'Webhook update successfully',
                });
            } else {
                console.log(response.error);
                Modal.error({
                    title: 'Error',
                    content: 'Webhook update error: ' + response.error,
                });
            }
            setLoading(false);
        });
    }

    function showInfoModal(record) {

        const code_tv = {
            "name": record.name,
            "secret": demoMode ? 'no_show_in_demo_mode' : record.secret,
            "symbol": "{{ticker}}",
            "side": "{{strategy.order.action}}",
            "positionSide": "{{strategy.market_position}}",
            "price": "{{strategy.order.price}}",
            "qty": "{{strategy.order.contracts}}",
            "amount": "",
            "leverage": record.leverage.toString(),
            "margin": record.margin_mode
        };

        const code_tv_martin = {
            "name": record.name,
            "secret": demoMode ? 'no_show_in_demo_mode' : record.secret,
            "symbol": "{{ticker}}",
            "side": "{{strategy.order.action}}",
            "positionSide": "{{strategy.order.alert_message}}",
            "price": "",
            "qty": "{{strategy.order.contracts}}",
            "amount": "",
            "leverage": "{{plot(\"LEVERAGE\")}}",
            "margin": record.margin_mode
        };

        Modal.info({
            title: 'Webhook Info',
            width: 600,
            style: {top: 10},
            content: (
                <div>
                    <br/>
                    <strong>Webhook URL:</strong>&nbsp;
                    <Tooltip title="Copy to clipboard">
                        <CopyToClipboard
                            text="https://wh.cdzv.com/webhook"
                            onCopy={() => {
                                Modal.success({
                                    title: 'Success',
                                    content: 'Webhook URL copied to clipboard',
                                })
                            }}
                        >
                            <CopyOutlined/>
                        </CopyToClipboard>
                    </Tooltip>
                    <pre id={"webhook_url"}
                         style={{background: "#e1f3f6"}}>https://wh.cdzv.com/webhook METHOD: POST</pre>
                    <br/>
                    <strong>Message from <span style={{color: "red"}}>Condition Manager Strategy</span>:</strong>&nbsp;
                    <Tooltip title="Copy to clipboard">

                        <CopyToClipboard
                            text={JSON.stringify(code_tv, null, 2)}
                            onCopy={() => {
                                Modal.success({
                                    title: 'Success',
                                    content: 'Message copied to clipboard',
                                })
                            }}
                        >
                            <CopyOutlined/>
                        </CopyToClipboard>
                    </Tooltip>
                    <pre id={"code_tv"}
                         style={{background: "#e1f3f6"}}>{JSON.stringify(code_tv, null, 2)}</pre>
                    <br/>
                    <strong>Message from <span style={{color: "red"}}>Martin Grid Strategy</span>:</strong>&nbsp;
                    <Tooltip title="Copy to clipboard">
                        <CopyToClipboard
                            text={JSON.stringify(code_tv_martin, null, 2).replaceAll("\\", "")}
                            onCopy={() => {
                                Modal.success({
                                    title: 'Success',
                                    content: 'Message copied to clipboard',
                                })
                            }}
                        >
                            <CopyOutlined/>
                        </CopyToClipboard>
                    </Tooltip>
                    <pre id={"code_tv_martin"}
                         style={{background: "#e1f3f6"}}>{JSON.stringify(code_tv_martin, null, 2).replaceAll("\\", "")}</pre>
                </div>
            ),
            onOk() {
            },
        });
    }

    // show modal for add new webhook
    const modalAddWebhook = () => {

        if (!dataSourceExchange || dataSourceExchange.length === 0) {
            return <></>;
        }

        let exchanges_list = dataSourceExchange.map((item) => {
            if (item.is_active === 1) {
                return {
                    value: item.key,
                    type: item.type,
                    search_label: item.exchange.toUpperCase() + ' ' + item.type.toUpperCase() + ' ' + item.name,
                    label: <>
                        <span>{item.exchange.toUpperCase()} | {item.type.toUpperCase()} |</span><strong> {item.name}</strong></>
                }
            } else {
                return {}
            }
        });

        exchanges_list = exchanges_list.filter((item) => Object.keys(item).length !== 0);

        if (exchanges_list.length === 0) {
            return <></>;
        }

        // // exclude already connected exchanges to webhooks dataSource
        // dataSource.forEach((item) => {
        //     exchanges_list = exchanges_list.filter((exchange) => exchange.value !== item.exchange_key);
        // });

        return (
            <Modal
                open={showAddWebhookModal}
                title={<strong>🆕 Create new Webhook</strong>}
                height={600}
                width={700}
                onOk={() => saveNewWebhookToDB()}
                onCancel={() => setShowAddWebhookModal(false)}
            >
                <Card>
                    <Space
                        direction="vertical"
                        size="large"
                        style={{
                            display: 'flex',
                        }}>
                        <Input.Group compact>
                            <Input disabled value="Webhook name"
                                   style={{width: '25%', color: 'rgba(0, 0, 0, 0.65)', cursor: 'auto'}}/>
                            <Input
                                onChange={(e) => setInputWHName(e.target.value)}
                                placeholder="Webhook name"
                                value={inputWHName}
                                style={{width: '75%'}}
                                defaultValue={inputWHName}
                            />
                        </Input.Group>
                        <Input.Group compact>
                            <Input disabled value="Exchange"
                                   style={{width: '25%', color: 'rgba(0, 0, 0, 0.65)', cursor: 'auto'}}/>
                            <Select
                                showSearch
                                onChange={(value) => {
                                    setShowFuturesInputFields(exchanges_list.find((exchange) => exchange.value === value).type === 'future');
                                    setInputExchangeId(value);
                                }}
                                placeholder="Select Connector to exchange"
                                // value={inputExchangeId}
                                style={{width: '75%'}}
                                options={exchanges_list}
                                filterOption={filterOption}
                                // defaultValue={exchanges_list[0].value}
                            />
                        </Input.Group>
                        {showFuturesInputFields && <>
                            <Input.Group compact>
                                <Input disabled value="Margin mode"
                                       style={{width: '25%', color: 'rgba(0, 0, 0, 0.65)', cursor: 'auto'}}/>
                                <Select
                                    onChange={(value) => setInputMarginMode(value)}
                                    style={{width: '75%'}}
                                    value={inputMarginMode}
                                    options={[
                                        {value: 'isolated', label: <span>Isolated</span>},
                                        {value: 'cross', label: <span>Cross</span>}
                                    ]}
                                    defaultValue={"isolated"}
                                />
                            </Input.Group>
                            <Input.Group compact>
                                <Input disabled value="Leverage"
                                       style={{width: '25%', color: 'rgba(0, 0, 0, 0.65)', cursor: 'auto'}}/>
                                <Select
                                    onChange={(value) => setInputLeverage(value)}
                                    style={{width: '75%'}}
                                    value={inputLeverage}
                                    options={[
                                        {value: 1, label: <span>1</span>},
                                        {value: 2, label: <span>2</span>},
                                        {value: 3, label: <span>3</span>},
                                        {value: 4, label: <span>4</span>},
                                        {value: 5, label: <span>5</span>},
                                        {value: 6, label: <span>6</span>},
                                        {value: 7, label: <span>7</span>},
                                        {value: 8, label: <span>8</span>},
                                        {value: 9, label: <span>9</span>},
                                        {value: 10, label: <span>10</span>},
                                        {value: 15, label: <span>15</span>},
                                        {value: 20, label: <span>20</span>},
                                        {value: 30, label: <span>30</span>},
                                        {value: 40, label: <span>40</span>},
                                        {value: 50, label: <span>50</span>},
                                        {value: 100, label: <span>100</span>}
                                    ]}
                                    defaultValue={1}
                                />
                            </Input.Group>
                        </>}
                        <Input.Group compact>
                            <Input disabled value="Order amount, USD"
                                   style={{width: '25%', color: 'rgba(0, 0, 0, 0.65)', cursor: 'auto'}}/>
                            <Input
                                onChange={(e) => setInputOrderAmount(e.target.value)}
                                value={inputOrderAmount}
                                placeholder="Order amount, USD"
                                style={{width: '70%'}}
                                defaultValue='100'
                            />
                            &nbsp;&nbsp;&nbsp;
                            <Tooltip
                                placement="top"
                                overlayInnerStyle={{
                                    width: '500px',
                                    backgroundColor: '#d1d9da',
                                    color: 'black',
                                }}
                                title={
                                    <>
                                        The order size in USD that will be opened on the exchange. When using 1
                                        leverage (no leverage), the order size will be equal to the margin deposit. With
                                        2 leverage, the margin deposit will be 50% of the order size.<br/><br/>

                                        For example, if you want to open a position of 1000 USD:<br/>
                                        * With 1 leverage, you will need 1000 USD as a margin deposit.<br/>
                                        * With 2 leverage, you will need 500 USD as a margin deposit.<br/><br/>

                                        It's important to note that the minimum order size may vary depending on the
                                        exchange and the specific traded asset. Before opening a position, you should
                                        always check the minimum order size requirements on your chosen exchange for
                                        each specific asset. This will help avoid errors when placing orders and ensure
                                        your trading complies with the exchange's rules and restrictions.
                                    </>
                                }
                            >
                                <InfoCircleOutlined style={{fontSize: '20px'}}/>
                            </Tooltip>
                        </Input.Group>
                    </Space>
                </Card>
                <strong style={{color: "#ff0811"}}>* Fill in all fields and click <strong>'OK'</strong> to
                    create the Webhook.</strong>
            </Modal>
        )
    }

    // show modal for Edit webhook
    const modalEditWebhook = () => {

        if (!dataSourceExchange || dataSourceExchange.length === 0) {
            return <></>;
        }

        return (
            <Modal
                open={showEditWebhookModal}
                title={<strong>📝 Edit webhook</strong>}
                height={600}
                width={700}
                onOk={() => editWebhookToDB()}
                onCancel={() => setShowEditWebhookModal(false)}
            >
                <Card>
                    <Space
                        direction="vertical"
                        size="small"
                        style={{
                            display: 'flex',
                        }}
                    >
                        <Input.Group compact>
                            <Input disabled value="Webhook name"
                                   style={{width: '25%', color: 'rgba(0, 0, 0, 0.65)', cursor: 'auto'}}/>
                            <Input
                                // addonBefore="Webhook name"
                                onChange={(e) => setInputWHName(e.target.value)}
                                placeholder="Webhook name"
                                value={inputWHName}
                                style={{width: '75%'}}
                                defaultValue={inputWHName}
                            />
                        </Input.Group>
                        {showFuturesInputFields && <>
                            <Input.Group compact>
                                <Input disabled value="Margin mode"
                                       style={{width: '25%', color: 'rgba(0, 0, 0, 0.65)', cursor: 'auto'}}/>
                                <Select
                                    onChange={(value) => setInputMarginMode(value)}
                                    style={{width: '75%'}}
                                    value={inputMarginMode}
                                    options={[
                                        {value: 'isolated', label: <span>Isolated</span>},
                                        {value: 'cross', label: <span>Cross</span>}
                                    ]}
                                    defaultValue={"isolated"}
                                />
                            </Input.Group>
                            <Input.Group compact>
                                <Input disabled value="Leverage"
                                       style={{width: '25%', color: 'rgba(0, 0, 0, 0.65)', cursor: 'auto'}}/>
                                <Select
                                    onChange={(value) => setInputLeverage(value)}
                                    style={{width: '75%'}}
                                    value={inputLeverage}
                                    options={[
                                        {value: 1, label: <span>1</span>},
                                        {value: 2, label: <span>2</span>},
                                        {value: 3, label: <span>3</span>},
                                        {value: 4, label: <span>4</span>},
                                        {value: 5, label: <span>5</span>},
                                        {value: 6, label: <span>6</span>},
                                        {value: 7, label: <span>7</span>},
                                        {value: 8, label: <span>8</span>},
                                        {value: 9, label: <span>9</span>},
                                        {value: 10, label: <span>10</span>},
                                        {value: 15, label: <span>15</span>},
                                        {value: 20, label: <span>20</span>},
                                        {value: 30, label: <span>30</span>},
                                        {value: 40, label: <span>40</span>},
                                        {value: 50, label: <span>50</span>},
                                        {value: 100, label: <span>100</span>}
                                    ]}
                                    defaultValue={1}
                                />
                            </Input.Group>
                        </>}
                        <Input.Group compact>
                            <Input disabled value="Order amount, USD"
                                   style={{width: '25%', color: 'rgba(0, 0, 0, 0.65)', cursor: 'auto'}}/>
                            <Input
                                onChange={(e) => setInputOrderAmount(e.target.value)}
                                value={inputOrderAmount}
                                placeholder="Order amount, USD"
                                style={{width: '70%'}}
                                defaultValue={inputOrderAmount}
                            />
                            &nbsp;&nbsp;&nbsp;
                            <Tooltip
                                placement="top"
                                overlayInnerStyle={{
                                    width: '500px',
                                    backgroundColor: '#d1d9da',
                                    color: 'black',
                                }}
                                title={
                                    <>
                                        The order size in USD that will be opened on the exchange. When using 1
                                        leverage (no leverage), the order size will be equal to the margin deposit. With
                                        2 leverage, the margin deposit will be 50% of the order size.<br/><br/>

                                        For example, if you want to open a position of 1000 USD:<br/>
                                        * With 1 leverage, you will need 1000 USD as a margin deposit.<br/>
                                        * With 2 leverage, you will need 500 USD as a margin deposit.<br/><br/>

                                        It's important to note that the minimum order size may vary depending on the
                                        exchange and the specific traded asset. Before opening a position, you should
                                        always check the minimum order size requirements on your chosen exchange for
                                        each specific asset. This will help avoid errors when placing orders and ensure
                                        your trading complies with the exchange's rules and restrictions.
                                    </>
                                }
                            >
                                <InfoCircleOutlined style={{fontSize: '20px'}}/>
                            </Tooltip>
                        </Input.Group>
                    </Space>
                </Card>
                <strong style={{color: "#ff0811"}}>* Fill in all fields and click <strong>'OK'</strong> to
                    edit the Webhook.</strong>
            </Modal>
        )
    }

    const columns = [
        {
            title: 'Active',
            dataIndex: 'is_active',
            align: 'center',
            width: '60px',
            render: (_, record) => (<Switch size="large" checked={record.is_active}
                                            onChange={() => handleChangeActive(record.key, !record.is_active)}/>),
            // defaultSortOrder: 'descend',
            sortIcon: (sorted) => sortIcon(sorted),
            sorter: (a, b) => a.is_active - b.is_active
        },
        {
            title: 'Name',
            dataIndex: 'name',
            align: 'center',
            width: '25%',
            render: (text) => {
                return <><strong>{text}</strong></>
            },
            sortIcon: (sorted) => sortIcon(sorted),
            defaultSortOrder: 'ascend',
            sorter: (a, b) => a.name.localeCompare(b.name),
            ...getColumnSearchProps('name', 'Search by Webhook name')
        },
        {
            title: 'Exchange',
            dataIndex: 'exchange',
            align: 'center',
            width: '200px',
            render: (text, record) => {
                return <>
                    <strong style={{color: '#617af8'}}>{record.exchange_name}</strong><br/>
                    <Image height={"30px"} src={record.logo} preview={false}/>
                </>
            },
            sortIcon: (sorted) => sortIcon(sorted),
            // defaultSortOrder: 'descend',
            sorter: (a, b) => a.exchange.localeCompare(b.exchange),
            ...getColumnSearchProps('exchange_name', 'Search by Connector name')
        },
        {
            title: 'Order Amount, USD',
            dataIndex: 'order_amount_usd',
            align: 'center',
            width: '250px',
            // defaultSortOrder: 'descend',
            sortIcon: (sorted) => sortIcon(sorted),
            sorter: (a, b) => a.order_amount_usd - b.order_amount_usd,
            render: (text) => {
                return <span style={{fontSize: 16, fontWeight: 700}}>{text}</span>
            },
            ...getColumnSearchProps('order_amount_usd', 'Search by Order amount')
        },
        {
            title: 'Leverage',
            dataIndex: 'leverage',
            align: 'center',
            width: '60px',
            render: (text) => {
                if (text === 1) {
                    return <span style={{color: 'green', fontSize: 16, fontWeight: 700}}>{text}</span>
                } else {
                    return <span style={{color: 'red', fontSize: 16, fontWeight: 700}}>{text}</span>
                }
            },
            // defaultSortOrder: 'descend',
            sortIcon: (sorted) => sortIcon(sorted),
            sorter: (a, b) => a.order_amount_usd - b.order_amount_usd
        },
        {
            title: 'Margin mode',
            dataIndex: 'margin_mode',
            align: 'center',
            width: '150px',
            render: (text) => {
                if (text === 'isolated') {
                    return <span style={{color: 'green', fontSize: 16}}>{text}</span>
                } else {
                    return <span style={{color: 'red', fontSize: 16}}>{text}</span>
                }
            },
            // defaultSortOrder: 'descend',
            sortIcon: (sorted) => sortIcon(sorted),
            sorter: (a, b) => a.margin_mode.localeCompare(b.margin_mode)
        },
        {
            title: '',
            dataIndex: 'operation',
            align: 'center',
            width: '100px',
            render: (_, record) =>
                dataSource.length >= 1 ? (
                    <>
                        <Space direction={"horizontal"}>
                            <Button style={{background: '#d1d9da'}}
                                    onClick={() => handleEdit(record)}>
                                <EditOutlined/>
                            </Button>
                            <Button type="text" style={{background: '#e80e19', color: "white"}}>
                                <Popconfirm title="Sure to delete webhook?"
                                            onConfirm={() => handleDeleteWebhook(record.key)}>
                                    <DeleteOutlined/>
                                </Popconfirm>
                            </Button>
                            <Button type="text" style={{background: '#82d976', color: "#030342"}}
                                    onClick={() => showInfoModal(record)}
                            >
                                Info
                            </Button>
                        </Space>
                    </>
                ) : null,
        },
    ];

    return (
        <>
            {modalAddWebhook()}
            {modalEditWebhook()}
            <Content>
                <Row gutter={8} justify={"center"} align={"top"}>
                    <Col span={24}>
                        <ConfigProvider
                            theme={{
                                components: {
                                    Table: {
                                        // fontSize: "16px",
                                        fontFamily: 'Courier New',
                                        headerBorderRadius: '10px',
                                        headerColor: '#ffffff',
                                        headerBg: '#272762',
                                        bodySortBg: '#ffffff',
                                        headerSortHoverBg: '#272762',
                                        headerSortActiveBg: '#272762',
                                        cellFontSizeSM: "16px",
                                        cellPaddingInline: '10px',
                                        cellPaddingBlockSM: '5px',
                                        cellPaddingInlineSM: '5px',
                                        colorLinkActive: '#b21a2d',
                                    },
                                },
                            }}
                        >
                            <Table
                                rowKey={record => record.ID}
                                size="small"
                                pagination={{hideOnSinglePage: true, pageSize: 8}}
                                loading={loading}
                                title={() =>
                                    <Row align={"middle"} justify={"end"}>
                                        <Button
                                            onClick={() => {
                                                getUserExchange();
                                                setInputWHName('');
                                                // setInputExchangeId('');
                                                setInputOrderAmount(100);
                                                setInputLeverage(1);
                                                setInputMarginMode('isolated');
                                                if (!dataSourceExchange || dataSourceExchange.length === 0) {
                                                    Modal.error({
                                                        title: 'Error',
                                                        content: 'You don\'t have any connected exchanges',
                                                    });
                                                } else {
                                                    setShowAddWebhookModal(true);
                                                }
                                            }}
                                            type="primary"
                                            style={{
                                                marginTop: '0%',
                                                marginBottom: '0%',
                                            }}
                                        >
                                            Create new Webhook
                                        </Button>
                                    </Row>
                                }
                                style={{
                                    marginTop: '2%',
                                    marginLeft: '5%',
                                    marginRight: '5%',
                                }}
                                bordered
                                dataSource={dataSource}
                                columns={columns}
                                onRow={(record) => {
                                    return {
                                        // onClick: () => handleEdit(record.ID), // click row
                                        onDoubleClick: () => handleEdit(record), // double click row
                                        // onContextMenu: event => {
                                        // }, // right button click row
                                        // onMouseEnter: event => {
                                        // }, // mouse enter row
                                        // onMouseLeave: event => {
                                        // }, // mouse leave row
                                    };
                                }}
                            />
                        </ConfigProvider>
                    </Col>
                </Row>
            </Content>
        </>
    );
}

export default Webhooks;