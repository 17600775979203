const gateways_list = [
    {
        name: "Finandy",
        description: "Trading terminal with the ability to receive signals via Webhook for Binance, OKX, Finandy exchanges. Flexible customization, good support.",
        brokers: ["Binance", "OKX", "Finandy"],
        crypto: true,
        stocks: false,
        url: "https://finandy.com/",
        free: true,
    }, {
        name: "3commas",
        description: "Signal Bot - designed to automate trading with Webhook signals.",
        brokers: ["Binance", "Bitfinex", "Bitstamp", "ByBit", "Coinbase", "HTX", "Kraken", "KuCoin", "OKX", 'Huobi'],
        crypto: true,
        stocks: false,
        url: "https://3commas.io/?utm_source=referral&utm_medium=cabinet&c=tc559561",
        free: true,
    }, {
        name: "OKX",
        description: "OKX cryptocurrency exchange.",
        brokers: ["OKX"],
        crypto: true,
        stocks: false,
        url: "https://www.okx.com/ua/join/53775592",
        free: true,
    }, {
        name: "Binance",
        description: "Binance cryptocurrency exchange.",
        brokers: ["Binance"],
        crypto: true,
        stocks: false,
        url: "https://accounts.binance.com/en/register?ref=OSHDFY9J",
        free: true,
    }, {
        name: "ByBit",
        description: "ByBit cryptocurrency exchange.",
        brokers: ["ByBit"],
        crypto: true,
        stocks: false,
        url: "https://www.bybit.com/uk-UA/invite/?ref=0NN1MN",
        free: true,
    }, {
        name: "SignalStack",
        description: "Turn Webhook alerts from CDZV into live orders in 23 brokerage accounts.",
        crypto: true,
        stocks: true,
        brokers: ['Alpaca Markets', 'AutoShares', 'BingX', 'BitMart', 'BitMEX', 'BlockChain.com', 'ByBit', 'Capital.com', 'Coinbase', 'E*Trade', 'Fyers', 'Gemini', 'Interactive Brokers', 'Ironbeam', 'KuCoin', 'Lime Financial', 'Oanda', 'Optimus Futures', 'Phemex', 'TastyTrade', 'TradeStation', 'Tradier', 'Trading212', 'Woo X', 'XTB'],
        url: "https://signalstack.com",
        free: false,
    }, {
        name: "TradersPost",
        description: "TradersPost can automate strategies by accepting Webhook notifications from popular brokers such as TDAmeritrade, TradeStation, Coinbase, Interactive Brokers, and Alpaca.",
        brokers: ["TradeStation", "Alpaca", "Tradier", "Coinbase", "Tradovate", "Interactive Brokers", "TDAmeritrade", "Robinhood"],
        crypto: false,
        stocks: true,
        url: "https://traderspost.io",
        free: false,
    }, {
        name: "Alertatron",
        description: "Automates trading on cryptocurrency exchanges with Webhook notifications.",
        brokers: ["ByBit", "Binance", "OKX", "Bitget", "Bitfinex", "Deribit", "Phemex", "BitMEX", "BingX"],
        crypto: true,
        stocks: false,
        url: "https://alertatron.com",
        free: false,
    }, {
        name: "Aleeert",
        description: "Automates trading on cryptocurrency exchanges with Webhook notifications.",
        brokers: ["Binance"],
        crypto: true,
        stocks: false,
        url: "https://aleeert.com/",
        free: false,
    }, {
        name: "Capitalise",
        description: "One of the few services that has direct agreements with the brokers it works with.",
        brokers: ["ACY Securities", "AvaTrade", "Binance", "CFI", "Eightcap", "Fidelity", "Forex.com", "FXCM", "Interactive Brokers", "Pepperstone", "Tickmil"],
        crypto: false,
        stocks: true,
        url: "https://capitalise.ai",
        free: false,
    }, {
        name: "Cornix",
        description: "A platform that allows you to take Webhook signals and send them to crypto exchanges.",
        brokers: ["ByBit", "Binance", "KuCoin", "OKX", "Huobi", "Bitmex"],
        crypto: true,
        stocks: false,
        url: "https://capitalise.ai",
        free: false,
    }, {
        name: "Cryptohopper",
        description: "A platform that allows you to take Webhook signals and send them to crypto exchanges.",
        brokers: ["Coinbase", "Bitvavo", "Binance", "Crypto.com", "KuCoin", "ByBit", "Kraken", "BingX", "BitMart", "OKX", "EXMO", "Bitfinex", "Poloniex", "HTX", "HitBTC", "Huoibi"],
        crypto: true,
        stocks: false,
        url: "https://www.cryptohopper.com/",
        free: false,
    }, {
        name: "Quadency",
        description: "Turn your TradingView alerts into automated trading orders with 'TradingView Bot'.",
        brokers: ["AAX", "Binance", "Kucoin", "Liquid", "OKX", "Bitfinex", "Bittrex", "Coinbase", "Gemini", "HitBTC", "Kraken", "Poloniex"],
        crypto: true,
        stocks: false,
        url: "https://quadency.com/",
        free: false,
    }, {
        name: "Tickerly",
        description: "Tickerly’s fast and flexible service enables you to effortlessly automate any strategy on forex, stock, futures or cryptocurrency assets.",
        brokers: ["OANDA", "Capital.com", "DXTrade", "FTMO", "Alpaca", "Binance", "Kraken", "ByBit", "KuCoin", "MEXC Global", "APEX", "Bitget"],
        crypto: true,
        stocks: true,
        url: "https://tickerly.net/",
        free: false,
    }, {
        name: "TradingConnector",
        description: "To be able to execute trades inside MetaTrader, you need to make TradingView trigger the ALERTS. Alerts are the granular unit of TradingConnector auto-trading system. Having said that - copy-trading manual/paper-trades from TradingView to MetaTrader is not possible. You need to trigger alerts.",
        brokers: ["MetaTrader"],
        crypto: true,
        stocks: true,
        url: "https://tradingconnector.com",
        free: false,
    }, {
        name: "Tradingview.To",
        description: "Create your webhook URL to use in the TradingView alert configuration. Our servers will send your alert to where you need them in realtime!",
        brokers: ["Binance", "BingX", "Bitmex", "ByBit", "DXTrade", "IQ Option", "KuCoin", "MetaTrader"],
        crypto: true,
        stocks: true,
        url: "https://tradingview.to/",
        free: false,
    }, {
        name: "WunderTrading",
        description: "TradingView is a cloud-based trading platform that integrates market information and offers unique research, analysis, and trading capabilities. One of the core benefits of using WunderTrading for traders is the ability to build a TradingView bot that can be easily automated using our platform. Such an approach would enhance your trading results and will make sure that you stick to your trading system.",
        brokers: ["Binance", "ByBit", "OKX", "Bitget", "Gate.IO", "BingX", "KuCoin", "Kraken", "Deribit", "BitMEX", "HTX", "Huoibi", "Bitfinex", "MEXC", "WOOX", "Coinbase", "Phemex"],
        crypto: true,
        stocks: false,
        url: "https://wundertrading.com/",
        free: false,
    }, {
        name: "Alertatron",
        description: "Automates trading on cryptocurrency exchanges with Webhook notifications.",
        brokers: ["ByBit", "Binance", "OKX", "Bitget", "Bitfinex", "Deribit", "Phemex", "BitMEX", "BingX"],
        crypto: true,
        stocks: false,
        url: "https://alertatron.com",
        free: false,
    }, {
        name: "AutoView",
        description: "Autoview is a website application that places the trades you want, when you want them. Whether you want to set it and forget it or dive into the world of custom scripts, Autoview listens to your alert signals so all you have to do is sit back and enjoy.",
        crypto: false,
        stocks: false,
        brokers: ["Alpaca", "AscendEX", "Binance", "Binance.US", "Bitfinex", "Bitget", "BitMEX", "ByBit", "capital.com", "coinbase", "crypto.com", "Deribit", "Gate.io", "Gemini", "Huobi", "HTX", "Kraken", "KuCoin", "OANDA", "OKX", "OkCoin", "Poloniex"],
        url: "https://autoview.com/",
        free: false,
    }, {
        name: "Webhook.site",
        description: "A service for testing Webhook messages. You can specify it as a message receiver and see the message. A great way to test sending alerts before connecting real trading to broker and exchange services.",
        crypto: false,
        stocks: false,
        brokers: ["test"],
        url: "https://webhook.site/",
        free: true,
    }
];

export default gateways_list;