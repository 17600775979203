import React from 'react';
import {Spin} from "antd";
import "./index.css";

function SpinLoader(props) {
    const {tip, loading} = props;
    if (!loading) {
        return (
            <>
                {props.children}
            </>
        );
    }

    return (
        <div className={"loader_wrap"}>
            <Spin size="large" tip={tip}>
                {props.children}
            </Spin>
        </div>
    );
}

export default SpinLoader;