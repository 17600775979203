import React, {useEffect, useRef, useState} from 'react';
import {
    Button,
    Card,
    Col,
    ConfigProvider,
    Image,
    Input,
    Modal,
    Popconfirm,
    Row,
    Select,
    Space,
    Switch,
    Table,
    Tooltip
} from 'antd';
import {Content} from "antd/es/layout/layout";
import API from "../../../api/user";
import exchanges from "../Connectors/exchanges";
import StrategyCard from "../../components/StrategyCard";
import {
    CaretDownOutlined,
    CaretUpOutlined, ClearOutlined, CloseOutlined,
    DeleteOutlined,
    EditOutlined, FullscreenExitOutlined,
    InfoCircleOutlined,
    SearchOutlined
} from "@ant-design/icons";
import {mobXStore, observer} from "../../../store/";
import {showModalDemoWarning} from "../../../Helpers";

const filterOption = (input, option) =>
    (option?.search_label ?? '').toLowerCase().includes(input.toLowerCase());

function get_strategy_id(strategy_id) {
    if (strategy_id > 1000) {
        return '#ID_' + strategy_id;
    } else if (strategy_id > 100) {
        return '#ID_0' + strategy_id;
    } else if (strategy_id > 10) {
        return '#ID_00' + strategy_id;
    } else {
        return '#ID_000' + strategy_id;
    }
}

// BotsExchange component
function BotsExchange(props) {

    const apiUser = new API();

    const [showAddBotModal, setShowAddBotModal] = useState(false);
    const [showEditBotModal, setShowEditBotModal] = useState(false);

    const [showFuturesInputFields, setShowFuturesInputFields] = useState(false);


    const [demoMode, setDemoMode] = useState(false);

    const [editRecordId, setEditRecordId] = useState(0);
    const [inputBotName, setInputBotName] = useState('');
    const [inputExchangeId, setInputExchangeId] = useState(0);
    const [inputStrategyId, setInputStrategyId] = useState(0);
    const [inputOrderAmount, setInputOrderAmount] = useState(100);
    const [inputLeverage, setInputLeverage] = useState(1);
    const [inputMarginMode, setInputMarginMode] = useState('cross');
    const [inputClosePositionMode, setInputClosePositionMode] = useState('CLOSE');

    const [dataSourceExchange, setDataSourceExchange] = useState([]);
    const [strategyList, setStrategyList] = useState([]);
    const [dataSource, setDataSource] = useState([]);
    const [loading, setLoading] = useState(true);
    const [initialized, setInitialized] = useState(false);

    const [selectedStrategy, setSelectedStrategy] = useState(null);

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const sortIcon = (sorted) => {
        // alert(JSON.stringify(sorted))
        if (sorted['sortOrder'] === 'descend') {
            return (<CaretDownOutlined
                style={{
                    color: '#1677ff'
                }}
            />)
        } else if (sorted['sortOrder'] === 'ascend') {
            return (<CaretUpOutlined
                style={{
                    color: '#1677ff'
                }}
            />)
        } else {
            return (<FullscreenExitOutlined
                style={{
                    color: 'white',
                }}
            />)
        }
    }

    const getColumnSearchProps = (dataIndex, tooltip_text = '') => ({
        filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters, close}) => (
            <div
                style={{
                    // backgroundColor: '#0f0fa8',
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Space direction={'horizontal'}>
                    <Input
                        ref={searchInput}
                        placeholder={tooltip_text}
                        value={selectedKeys[0]}
                        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{
                            marginBottom: 8,
                            width: 200,
                            display: 'block',
                        }}
                    />
                    <Button
                        type="button"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        <CloseOutlined/>
                    </Button>
                </Space>
                <br/>
                <Space direction={'horizontal'}>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined/>}
                        size="small"
                        style={{
                            width: 115,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && (handleReset(clearFilters) || handleSearch(selectedKeys, confirm, dataIndex) || close())}
                        size="small"
                        style={{
                            width: 115,
                        }}
                    >
                        <ClearOutlined/> Reset
                    </Button>
                    {/*<Button*/}
                    {/*    type="link"*/}
                    {/*    size="small"*/}
                    {/*    onClick={() => {*/}
                    {/*        confirm({*/}
                    {/*            closeDropdown: false,*/}
                    {/*        });*/}
                    {/*        setSearchText(selectedKeys[0]);*/}
                    {/*        setSearchedColumn(dataIndex);*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    Filter*/}
                    {/*</Button>*/}
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#f6adb5' : 'white',
                }}
            />
        ),
        sortIcon: (sorted) => sortIcon(sorted),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        // render: (text) =>
        //     searchedColumn === dataIndex ? (
        //         <Highlighter
        //             highlightStyle={{
        //                 backgroundColor: '#ffc069',
        //                 padding: 0,
        //             }}
        //             searchWords={[searchText]}
        //             autoEscape
        //             textToHighlight={text ? text.toString() : ''}
        //         />
        //     ) : (
        //         text
        //     ),
    });

    useEffect(() => {
        setDemoMode(mobXStore.getKey('demo_mode'));
        setInitialized(false);
        getUserExchange();
        getStrategy();
        getUserBots();
        setTimeout(() => {
            setInitialized(true);
        }, 1000);
    }, []);

    function getUserExchange() {
        setLoading(true);
        apiUser.getUserExchange().then((response) => {
            if (response.result) {
                // console.log(response);
                let res = JSON.parse(response.data);
                res.forEach((item) => {
                    let exchange = exchanges.find((exchange) => exchange.ccxt_name === item.exchange);
                    item.exchange_name = exchange.name;
                    item.logo = exchange.logo;
                });
                setDataSourceExchange(res);
                // console.log(response.data);
                setLoading(false);
            } else {
                // console.log(response.error);
                setLoading(false);
                apiUser.logout();
            }
        });
    }

    function getStrategy() {
        setLoading(true);
        apiUser.getStrategy().then((response) => {
            if (response.result) {

                // market = 'CRYPTO' skip
                const res = JSON.parse(response.data).filter((item) => item.market === 'CRYPTO');
                setStrategyList(res);

                // Show Add Bot Modal
                const stID = parseInt(mobXStore.getKey('addStrategyID'))
                if (stID > 0) {
                    setInputStrategyId(stID);
                    setSelectedStrategy(getStrategyById(stID));
                    setShowAddBotModal(true);
                    mobXStore.setKey('addStrategyID', 0);
                }
                setLoading(false);
                // console.log(response.data);
            } else {
                // console.log(response.error);
                setLoading(false);
                apiUser.logout();
            }
        });
    }

    // get Strategy by ID
    function getStrategyById(id = null) {
        if (!id) {
            return strategyList.filter((item) => item.ID === inputStrategyId)[0];
        } else {
            return strategyList.filter((item) => item.ID === id)[0];
        }
    }

    useEffect(() => {
        setSelectedStrategy(getStrategyById());
    }, [inputStrategyId]);

    function getUserBots() {
        setLoading(true);
        apiUser.getBots().then((response) => {
            if (response.result) {
                // console.log(response);
                let res = JSON.parse(response.data);
                res.forEach((item) => {
                    let exchange = exchanges.find((exchange) => exchange.ccxt_name === item.exchange);
                    // item.exchange_name = exchange.name;
                    item.logo = exchange.logo;
                });
                setDataSource(res);
                setLoading(false);
                // console.log(response.data);
            } else {
                // console.log(response.error);
                setLoading(false);
                apiUser.logout();
            }
        });
    }


    function handleChangeActive(key, is_active) {
        if (demoMode) {
            showModalDemoWarning();
            return;
        }
        setLoading(true);
        apiUser.changeActiveBot({id: key, is_active: is_active}).then((response) => {
            if (response.result) {
                getUserBots();
                // Modal.success({
                //     title: 'Success',
                //     content: 'Bot status changed successfully',
                // });
            } else {
                // console.log(response.error);
                Modal.error({
                    title: 'Error',
                    content: 'Bot status change error: ' + response.error,
                });
            }
            setLoading(false);
        });
    }

    function handleDeleteBoot(key) {
        if (demoMode) {
            showModalDemoWarning();
            return;
        }

        setLoading(true);
        apiUser.deleteBot({id: key}).then((response) => {
            if (response.result) {
                getUserBots();
                Modal.success({
                    title: 'Success',
                    content: 'Bot deleted successfully',
                });
            } else {
                // console.log(response.error);
                Modal.error({
                    title: 'Error',
                    content: 'Bot delete error: ' + response.error,
                });
            }
            setLoading(false);
        });
    }

    // Show modal for edit Bot properties
    function handleEdit(record) {
        setEditRecordId(record.ID);
        setInputBotName(record.name);
        setInputExchangeId(record.exchange_id);
        setInputOrderAmount(record.order_amount_usd);
        setInputClosePositionMode(record.close_position_mode);
        setInputLeverage(record.leverage);
        setInputMarginMode(record.margin_mode);

        if (record.exchange_market === 'future') {
            setShowFuturesInputFields(true);
        } else {
            setShowFuturesInputFields(false);
        }

        setShowEditBotModal(true);
    }

    // saveNewBotToDB
    function saveNewBotToDB() {
        if (demoMode) {
            showModalDemoWarning();
            return;
        }

        if (!inputBotName) {
            Modal.error({
                title: 'Error',
                content: 'Bot name is required',
            });
            return;
        }

        if (!inputExchangeId) {
            Modal.error({
                title: 'Error',
                content: 'Exchange is required',
            });
            return;
        }

        if (!inputStrategyId) {
            Modal.error({
                title: 'Error',
                content: 'Strategy is required',
            });
            return;
        }

        setShowAddBotModal(false);

        if (!inputOrderAmount || inputOrderAmount <= 0) {
            Modal.error({
                title: 'Error',
                content: 'Order amount is required',
            });
            return;
        }

        const data = {
            name: inputBotName,
            strategy_id: inputStrategyId,
            exchange_connectors_id: inputExchangeId,
            order_amount_usd: inputOrderAmount,
            leverage: inputLeverage,
            margin_mode: inputMarginMode,
            close_position_mode: inputClosePositionMode
        }

        apiUser.addNewBot(data).then((response) => {
            if (response.result) {
                getUserBots();
                Modal.success({
                    title: 'Success',
                    content: 'Bot created successfully',
                });

                mobXStore.setKey('addStrategyID', 0);
            } else {
                // console.log(response.error);
                Modal.error({
                    title: 'Error',
                    content: 'Bot create error: ' + response.error,
                });
            }
            setLoading(false);
        });
    }

    // editBotToDB
    function editBotToDB() {
        if (demoMode) {
            showModalDemoWarning();
            return;
        }

        setShowEditBotModal(false);

        const data = {
            id: editRecordId,
            name: inputBotName,
            order_amount_usd: inputOrderAmount,
            leverage: inputLeverage,
            margin_mode: inputMarginMode,
            close_position_mode: inputClosePositionMode
        }

        apiUser.updateBot(data).then((response) => {
            if (response.result) {
                getUserBots();
                Modal.success({
                    title: 'Success',
                    content: 'Bot update successfully',
                });
            } else {
                // console.log(response.error);
                Modal.error({
                    title: 'Error',
                    content: 'Bot update error: ' + response.error,
                });
            }
            setLoading(false);
        });
    }

    // show modal for add new Bot
    const modalAddBot = () => {

        if (!initialized) {
            return <></>
        }

        if (!dataSourceExchange || dataSourceExchange.length === 0) {
            return <></>;
        }

        if (!strategyList || strategyList.length === 0) {
            return <></>;
        }

        let exchanges_list = dataSourceExchange.map((item) => {
            if (item.is_active === 1) {
                return {
                    value: item.key,
                    type: item.type,
                    search_label: item.exchange.toUpperCase() + ' ' + item.type.toUpperCase() + ' ' + item.name,
                    label: <>
                        <span>{item.exchange.toUpperCase()} | {item.type.toUpperCase()} |</span><strong> {item.name}</strong></>
                }
            } else {
                return {}
            }
        });

        exchanges_list = exchanges_list.filter((item) => Object.keys(item).length !== 0);

        if (exchanges_list.length === 0) {
            return <></>;
        }

        let strategy_list = strategyList.map((item) => {
            return {
                value: item.ID,
                search_label: get_strategy_id(item.ID) + ' ' + item.name + ' ' + item.market + ' ' + (item.is_investing === 1 ? 'INVESTOR' : 'TRADER'),
                label: get_strategy_id(item.ID) + ' | ' + (item.is_investing === 1 ? 'INVESTOR' : 'TRADER') + ' | ' + item.market + ': ' + item.symbol
            }
        });

        return (
            <Modal
                open={showAddBotModal}
                title={<strong>🆕 Create new Copy Bot <span
                    style={{color: "#f17c8b"}}>to Exchange</span></strong>}
                style={{
                    marginTop: "-90px",
                }}
                height={600}
                width={720}
                onOk={() => saveNewBotToDB()}
                onCancel={() => setShowAddBotModal(false)}
            >
                <Card>
                    <Space
                        direction="vertical"
                        size="large"
                        style={{
                            display: 'flex',
                        }}>
                        <Input.Group compact>
                            <Input disabled value="Bot name"
                                   style={{width: '25%', color: 'rgba(0, 0, 0, 0.65)', cursor: 'auto'}}/>
                            <Input
                                onChange={(e) => setInputBotName(e.target.value)}
                                placeholder="Bot name"
                                value={inputBotName}
                                style={{width: '75%'}}
                                defaultValue={inputBotName}
                            />
                        </Input.Group>
                        <Input.Group compact>
                            <Input disabled value="Strategy"
                                   style={{width: '25%', color: 'rgba(0, 0, 0, 0.65)', cursor: 'auto'}}/>
                            <Select
                                showSearch
                                onChange={(value) => {
                                    setInputStrategyId(value);
                                }}
                                placeholder="Select Strategy"
                                value={inputStrategyId !== 0 ? inputStrategyId : null}
                                style={{width: '75%'}}
                                options={strategy_list}
                                filterOption={filterOption}
                                // defaultValue={strategy_list[0].value}

                            />
                        </Input.Group>
                        <Input.Group compact>
                            <Input disabled value="Exchange"
                                   style={{width: '25%', color: 'rgba(0, 0, 0, 0.65)', cursor: 'auto'}}/>
                            <Select
                                showSearch
                                onChange={(value) => {
                                    setShowFuturesInputFields(exchanges_list.find((exchange) => exchange.value === value).type === 'future');
                                    setInputExchangeId(value);
                                }}
                                placeholder="Select Connector to exchange"
                                // value={inputExchangeId}
                                style={{width: '75%'}}
                                options={exchanges_list}
                                filterOption={filterOption}
                                // defaultValue={exchanges_list[0].value}
                            />
                        </Input.Group>
                        {showFuturesInputFields && <>
                            <Input.Group compact>
                                <Input disabled value="Margin mode"
                                       style={{width: '25%', color: 'rgba(0, 0, 0, 0.65)', cursor: 'auto'}}/>
                                <Select
                                    onChange={(value) => setInputMarginMode(value)}
                                    style={{width: '75%'}}
                                    value={inputMarginMode}
                                    options={[
                                        {value: 'isolated', label: <span>Isolated</span>},
                                        {value: 'cross', label: <span>Cross</span>}
                                    ]}
                                    defaultValue={"isolated"}
                                />
                            </Input.Group>
                            <Input.Group compact>
                                <Input disabled value="Leverage"
                                       style={{width: '25%', color: 'rgba(0, 0, 0, 0.65)', cursor: 'auto'}}/>
                                <Select
                                    onChange={(value) => setInputLeverage(value)}
                                    style={{width: '75%'}}
                                    value={inputLeverage}
                                    options={[
                                        {value: 1, label: <span>1</span>},
                                        {value: 2, label: <span>2</span>},
                                        {value: 3, label: <span>3</span>},
                                        {value: 4, label: <span>4</span>},
                                        {value: 5, label: <span>5</span>},
                                        {value: 6, label: <span>6</span>},
                                        {value: 7, label: <span>7</span>},
                                        {value: 8, label: <span>8</span>},
                                        {value: 9, label: <span>9</span>},
                                        {value: 10, label: <span>10</span>},
                                        {value: 15, label: <span>15</span>},
                                        {value: 20, label: <span>20</span>},
                                        {value: 30, label: <span>30</span>},
                                        {value: 40, label: <span>40</span>},
                                        {value: 50, label: <span>50</span>},
                                        {value: 100, label: <span>100</span>}
                                    ]}
                                    defaultValue={1}
                                />
                            </Input.Group>
                        </>}
                        <Input.Group compact>
                            <Input disabled value="Order amount, USD"
                                   style={{width: '25%', color: 'rgba(0, 0, 0, 0.65)', cursor: 'auto'}}/>
                            <Input
                                onChange={(e) => setInputOrderAmount(e.target.value)}
                                value={inputOrderAmount}
                                placeholder="Order amount, USD"
                                style={{width: '70%'}}
                                defaultValue='100'
                            />
                            &nbsp;&nbsp;&nbsp;
                            <Tooltip
                                placement="top"
                                overlayInnerStyle={{
                                    width: '500px',
                                    backgroundColor: '#d1d9da',
                                    color: 'black',
                                }}
                                title={
                                    <>
                                        The order size in USD that will be opened on the exchange. When using 1
                                        leverage (no leverage), the order size will be equal to the margin deposit. With
                                        2 leverage, the margin deposit will be 50% of the order size.<br/><br/>

                                        For example, if you want to open a position of 1000 USD:<br/>
                                        * With 1 leverage, you will need 1000 USD as a margin deposit.<br/>
                                        * With 2 leverage, you will need 500 USD as a margin deposit.<br/><br/>

                                        It's important to note that the minimum order size may vary depending on the
                                        exchange and the specific traded asset. Before opening a position, you should
                                        always check the minimum order size requirements on your chosen exchange for
                                        each specific asset. This will help avoid errors when placing orders and ensure
                                        your trading complies with the exchange's rules and restrictions.
                                    </>
                                }
                            >
                                <InfoCircleOutlined style={{fontSize: '20px'}}/>
                            </Tooltip>
                        </Input.Group>
                        <Input.Group compact>
                            <Input disabled value="Close position mode"
                                   style={{width: '25%', color: 'rgba(0, 0, 0, 0.65)', cursor: 'auto'}}/>
                            <Select
                                onChange={(value) => setInputClosePositionMode(value)}
                                style={{width: '70%'}}
                                value={inputClosePositionMode}
                                options={[
                                    {value: 'CLOSE', label: <span>CLOSE</span>},
                                    {value: 'REDUCE', label: <span>REDUCE</span>}
                                ]}
                                defaultValue={"CLOSE"}
                            />
                            &nbsp;&nbsp;&nbsp;
                            <Tooltip
                                placement="top"
                                overlayInnerStyle={{
                                    width: '400px',
                                    backgroundColor: '#d1d9da',
                                    color: 'black',
                                }}
                                title={
                                    <>
                                        Select the mode of closing positions when the bot receives a signal to close a
                                        position.<br/>
                                        <strong>CLOSE</strong> - close all open positions on this instrument,<br/>
                                        <strong>REDUCE</strong> - reduce the position size by the order amount
                                    </>
                                }
                            >
                                <InfoCircleOutlined style={{fontSize: '20px'}}/>
                            </Tooltip>
                        </Input.Group>
                        {selectedStrategy !== null && <StrategyCard strategy={selectedStrategy}/>}
                    </Space>
                </Card>
                <strong style={{color: "#ff0811"}}>* Fill in all fields and click <strong>'OK'</strong> to
                    create the Copy Bot.</strong>
            </Modal>
        )
    }

    // show modal for Edit Bot
    const modalEditBot = () => {

        if (!dataSourceExchange || dataSourceExchange.length === 0) {
            return <></>;
        }

        return (
            <Modal
                open={showEditBotModal}
                title={<strong>📝 Edit Copy Bot <span
                    style={{color: "#f17c8b"}}>to Exchange</span></strong>}
                height={600}
                width={700}
                onOk={() => editBotToDB()}
                onCancel={() => setShowEditBotModal(false)}
            >
                <Card>
                    <Space
                        direction="vertical"
                        size="small"
                        style={{
                            display: 'flex',
                        }}
                    >
                        <Input.Group compact>
                            <Input disabled value="Bot name"
                                   style={{width: '25%', color: 'rgba(0, 0, 0, 0.65)', cursor: 'auto'}}/>
                            <Input
                                onChange={(e) => setInputBotName(e.target.value)}
                                placeholder="Bot name"
                                value={inputBotName}
                                style={{width: '75%'}}
                                defaultValue={inputBotName}
                            />
                        </Input.Group>
                        {showFuturesInputFields && <>
                            <Input.Group compact>
                                <Input disabled value="Margin mode"
                                       style={{width: '25%', color: 'rgba(0, 0, 0, 0.65)', cursor: 'auto'}}/>
                                <Select
                                    onChange={(value) => setInputMarginMode(value)}
                                    style={{width: '75%'}}
                                    value={inputMarginMode}
                                    options={[
                                        {value: 'isolated', label: <span>Isolated</span>},
                                        {value: 'cross', label: <span>Cross</span>}
                                    ]}
                                    defaultValue={"isolated"}
                                />
                            </Input.Group>
                            <Input.Group compact>
                                <Input disabled value="Leverage"
                                       style={{width: '25%', color: 'rgba(0, 0, 0, 0.65)', cursor: 'auto'}}/>
                                <Select
                                    onChange={(value) => setInputLeverage(value)}
                                    style={{width: '75%'}}
                                    value={inputLeverage}
                                    options={[
                                        {value: 1, label: <span>1</span>},
                                        {value: 2, label: <span>2</span>},
                                        {value: 3, label: <span>3</span>},
                                        {value: 4, label: <span>4</span>},
                                        {value: 5, label: <span>5</span>},
                                        {value: 6, label: <span>6</span>},
                                        {value: 7, label: <span>7</span>},
                                        {value: 8, label: <span>8</span>},
                                        {value: 9, label: <span>9</span>},
                                        {value: 10, label: <span>10</span>},
                                        {value: 15, label: <span>15</span>},
                                        {value: 20, label: <span>20</span>},
                                        {value: 30, label: <span>30</span>},
                                        {value: 40, label: <span>40</span>},
                                        {value: 50, label: <span>50</span>},
                                        {value: 100, label: <span>100</span>}
                                    ]}
                                    defaultValue={1}
                                />
                            </Input.Group>
                        </>}
                        <Input.Group compact>
                            <Input disabled value="Order amount, USD"
                                   style={{width: '25%', color: 'rgba(0, 0, 0, 0.65)', cursor: 'auto'}}/>
                            <Input
                                onChange={(e) => setInputOrderAmount(e.target.value)}
                                value={inputOrderAmount}
                                placeholder="Order amount, USD"
                                style={{width: '70%'}}
                                defaultValue={inputOrderAmount}
                            />
                            &nbsp;&nbsp;&nbsp;
                            <Tooltip
                                placement="top"
                                overlayInnerStyle={{
                                    width: '500px',
                                    backgroundColor: '#d1d9da',
                                    color: 'black',
                                }}
                                title={
                                    <>
                                        The order size in USD that will be opened on the exchange. When using 1
                                        leverage (no leverage), the order size will be equal to the margin deposit. With
                                        2 leverage, the margin deposit will be 50% of the order size.<br/><br/>

                                        For example, if you want to open a position of 1000 USD:<br/>
                                        * With 1 leverage, you will need 1000 USD as a margin deposit.<br/>
                                        * With 2 leverage, you will need 500 USD as a margin deposit.<br/><br/>

                                        It's important to note that the minimum order size may vary depending on the
                                        exchange and the specific traded asset. Before opening a position, you should
                                        always check the minimum order size requirements on your chosen exchange for
                                        each specific asset. This will help avoid errors when placing orders and ensure
                                        your trading complies with the exchange's rules and restrictions.
                                    </>
                                }
                            >
                                <InfoCircleOutlined style={{fontSize: '20px'}}/>
                            </Tooltip>
                        </Input.Group>
                        <Input.Group compact>
                            <Input disabled value="Close position mode"
                                   style={{width: '25%', color: 'rgba(0, 0, 0, 0.65)', cursor: 'auto'}}/>
                            <Select
                                onChange={(value) => setInputClosePositionMode(value)}
                                style={{width: '70%'}}
                                value={inputClosePositionMode}
                                options={[
                                    {value: 'CLOSE', label: <span>CLOSE</span>},
                                    {value: 'REDUCE', label: <span>REDUCE</span>}
                                ]}
                                defaultValue={"CLOSE"}
                            />
                            &nbsp;&nbsp;&nbsp;
                            <Tooltip
                                placement="top"
                                overlayInnerStyle={{
                                    width: '400px',
                                    backgroundColor: '#d1d9da',
                                    color: 'black',
                                }}
                                title={
                                    <>
                                        Select the mode of closing positions when the bot receives a signal to close a
                                        position.<br/>
                                        <strong>CLOSE</strong> - close all open positions on this instrument,<br/>
                                        <strong>REDUCE</strong> - reduce the position size by the order amount
                                    </>
                                }
                            >
                                <InfoCircleOutlined style={{fontSize: '20px'}}/>
                            </Tooltip>
                        </Input.Group>
                    </Space>
                </Card>
                <strong style={{color: "#ff0811"}}>* Fill in all fields and click <strong>'OK'</strong> to
                    edit the Copy Bot.</strong>
            </Modal>
        )
    }

    const columns = [
        {
            title: 'Active',
            dataIndex: 'is_active',
            align: 'center',
            width: '60px',
            // defaultSortOrder: 'descend',
            sortIcon: (sorted) => sortIcon(sorted),
            sorter: (a, b) => a.is_active - b.is_active,
            render: (_, record) => (<Switch size="large" checked={record.is_active}
                                            onChange={() => handleChangeActive(record.ID, !record.is_active)}/>)
        },
        {
            title: 'Name',
            dataIndex: 'name',
            align: 'center',
            width: '15%',
            render: (text) => {
                return <><strong>{text}</strong></>
            },
            // defaultSortOrder: 'descend',
            sorter: (a, b) => a.name.localeCompare(b.name),
            ...getColumnSearchProps('name', 'Search by Bot name'),
        },
        {
            title: 'Strategy',
            dataIndex: 'strategy_id',
            align: 'center',
            width: '150px',
            render: (text) => {
                return <>
                    <Tooltip
                        placement={"right"}
                        title={<StrategyCard strategy={getStrategyById(text)}/>}
                        overlayInnerStyle={{
                            width: '550px',
                            backgroundColor: '#d1d9da',
                            color: 'black',
                        }}
                    >
                        <strong>{get_strategy_id(text)} <InfoCircleOutlined/></strong>
                    </Tooltip>
                </>
            },
            defaultSortOrder: 'ascend',
            sorter: (a, b) => a.strategy_id - b.strategy_id,
            ...getColumnSearchProps('strategy_id', 'Search by ID (e.g. 1, 25, etc.)'),
        },
        {
            title: 'Exchange',
            dataIndex: 'exchange',
            align: 'center',
            width: '300px',
            render: (text, record) => {
                return <>
                    <strong style={{color: '#617af8'}}>{record.exchange_name}</strong><br/>
                    <Image height={"30px"} src={record.logo} preview={false}/>
                </>
            },
            // defaultSortOrder: 'descend',
            sorter: (a, b) => a.exchange.localeCompare(b.exchange),
            ...getColumnSearchProps('exchange_name', 'Search by Connector name'),
        },
        {
            title: 'Leverage',
            dataIndex: 'leverage',
            align: 'center',
            width: '60px',
            // defaultSortOrder: 'descend',
            sortIcon: (sorted) => sortIcon(sorted),
            sorter: (a, b) => a.leverage - b.leverage,
            render: (text) => {
                if (text === 1) {
                    return <span style={{color: 'green', fontSize: 16, fontWeight: 700}}>{text}</span>
                } else {
                    return <span style={{color: 'red', fontSize: 16, fontWeight: 700}}>{text}</span>
                }
            }
        },
        {
            title: 'Margin mode',
            dataIndex: 'margin_mode',
            align: 'center',
            width: '60px',
            sortIcon: (sorted) => sortIcon(sorted),
            // defaultSortOrder: 'descend',
            sorter: (a, b) => a.margin_mode.localeCompare(b.margin_mode),
            render: (text) => {
                if (text === 'isolated') {
                    return <span style={{color: 'green', fontSize: 16}}>{text}</span>
                } else {
                    return <span style={{color: 'red', fontSize: 16}}>{text}</span>
                }
            },
        },
        {
            title: 'Close Position Mode',
            dataIndex: 'close_position_mode',
            align: 'center',
            width: '300px',
            // defaultSortOrder: 'descend',
            sortIcon: (sorted) => sortIcon(sorted),
            sorter: (a, b) => a.close_position_mode.localeCompare(b.close_position_mode)
        },
        {
            title: 'Order Amount, USD',
            dataIndex: 'order_amount_usd',
            align: 'center',
            width: '250px',
            // defaultSortOrder: 'descend',
            sortIcon: (sorted) => sortIcon(sorted),
            sorter: (a, b) => a.order_amount_usd - b.order_amount_usd,
            render: (text, record) => {
                return <span style={{fontSize: 16, fontWeight: 700}}>{text}</span>
            }
        },
        {
            title: '',
            dataIndex: 'operation',
            align: 'center',
            width: '200px',
            render: (_, record) =>
                dataSource.length >= 1 ? (
                    <>
                        <Space direction={"horizontal"}>
                            <Button style={{background: '#d1d9da'}}
                                    onClick={() => handleEdit(record)}>
                                <EditOutlined/>
                            </Button>
                            <Button type="text" style={{background: '#e80e19', color: "white"}}>
                                <Popconfirm title="Sure to delete Copy Bot?"
                                            onConfirm={() => handleDeleteBoot(record.ID)}>
                                    <DeleteOutlined/>
                                </Popconfirm>
                            </Button>
                        </Space>
                    </>
                ) : null,
        },
    ];

    return (
        <>
            {!loading && <>
                {modalAddBot()}
                {modalEditBot()}
            </>}
            <Content>
                <Row gutter={8} justify={"center"} align={"top"}>
                    <Col span={24}>
                        <ConfigProvider
                            theme={{
                                components: {
                                    Table: {
                                        // fontSize: "16px",
                                        fontFamily: 'Courier New',
                                        headerBorderRadius: '10px',
                                        headerColor: '#ffffff',
                                        headerBg: '#272762',
                                        bodySortBg: '#ffffff',
                                        headerSortHoverBg: '#272762',
                                        headerSortActiveBg: '#272762',
                                        cellFontSizeSM: "16px",
                                        cellPaddingInline: '10px',
                                        cellPaddingBlockSM: '5px',
                                        cellPaddingInlineSM: '5px',
                                        colorLinkActive: '#b21a2d',
                                    },
                                },
                            }}
                        >
                            <Table
                                rowKey={record => record.ID}
                                size="small"
                                pagination={{hideOnSinglePage: true, pageSize: 8}}
                                loading={loading}
                                title={() =>
                                    <Row align={"middle"} justify={"end"}>
                                        <Button
                                            onClick={() => {
                                                getUserExchange();
                                                getStrategy();
                                                setInputBotName('');
                                                // setInputExchangeId('');
                                                setInputStrategyId(0);
                                                setInputOrderAmount(100);
                                                setInputLeverage(1);
                                                setInputMarginMode('isolated');
                                                if (!dataSourceExchange || dataSourceExchange.length === 0) {
                                                    Modal.error({
                                                        title: 'Error',
                                                        content: 'You don\'t have any connected exchanges',
                                                    });
                                                } else {
                                                    setShowAddBotModal(true);
                                                }
                                            }}
                                            type="primary"
                                            style={{
                                                marginTop: '0%',
                                                marginBottom: '0%',
                                            }}
                                        >
                                            Create new Copy Bot (<span
                                            style={{color: "#fccece"}}>to Exchange</span>)
                                        </Button>
                                    </Row>
                                }
                                style={{
                                    marginTop: '2%',
                                    marginLeft: '5%',
                                    marginRight: '5%',
                                }}
                                bordered
                                dataSource={dataSource}
                                columns={columns}
                                onRow={(record) => {
                                    return {
                                        // onClick: () => handleEdit(record.ID), // click row
                                        onDoubleClick: () => handleEdit(record), // double click row
                                        // onContextMenu: event => {
                                        // }, // right button click row
                                        // onClick: event => {
                                        //     console.log(event)
                                        //     setInputStrategyId(record.strategy_id);
                                        //     // alert(getStrategyById())
                                        //     Modal.success({
                                        //         title: 'Success',
                                        //         width: 700,
                                        //         content: <StrategyCard strategy={getStrategyById()}/>,
                                        //     });
                                        // }, // mouse enter row
                                        // onMouseLeave: event => {
                                        // }, // mouse leave row
                                    };
                                }}
                            />
                        </ConfigProvider>
                    </Col>
                </Row>
            </Content>
        </>
    );
}

export default observer(BotsExchange);