import React, {useEffect, useState} from "react";
import API from "../../../api/user";
import {Badge, Button, Card, Col, ConfigProvider, Image, Modal, Row, Skeleton, Space, Tag, Tooltip} from "antd";
import StrategyCard from "../../components/StrategyCard";
import Search from "antd/es/input/Search";
import {Link} from "react-router-dom";
import {TinyColor} from '@ctrl/tinycolor';
import {mobXStore} from "../../../store";
import {
    ApartmentOutlined,
    ApiOutlined,
    AreaChartOutlined,
    InfoCircleOutlined,
    LinkOutlined,
    RobotOutlined
} from "@ant-design/icons";
import {showModalDemoWarning} from "../../../Helpers";

const TG_Channel_with_signals = 'https://t.me/+dOh0ycP9XgJkMTVi';

function Strategy(props) {

    const colorsButton1 = ['#0f0fa8', '#617af8'];

    const apiUser = new API();

    const [showModal, setShowModal] = useState(false);

    const [loading, setLoading] = useState(false);

    const [strategyList, setStrategyList] = useState([]);

    const [search, setSearch] = useState([]);

    const [searchValue, setSearchValue] = useState('')

    const [demoMode, setDemoMode] = useState(false);

    const format_text = (text) => {
        // 1 -> 0001
        // 10 -> 0010
        // 100 -> 0100
        // 1000 -> 1000
        return text.toString().padStart(4, '0');
    }

    const getHoverColors = (colors) =>
        colors.map((color) => new TinyColor(color).lighten(5).toString());
    const getActiveColors = (colors) =>
        colors.map((color) => new TinyColor(color).darken(5).toString());

    const onSearch = e => {
        // console.log(e.target.value);
        setSearchValue(e.target.value);
        setSearch(strategyList.filter(module => {
            return (
                module.name.toLowerCase().includes(e.target.value.toLowerCase()) ||
                module.direction.toLowerCase().includes(e.target.value.toLowerCase()) ||
                module.tags.toLowerCase().includes(e.target.value.toLowerCase()) ||
                module.asset_type.toLowerCase().includes(e.target.value.toLowerCase()) ||
                e.target.value.toLowerCase() === 'trader' && !module.is_investing ||
                e.target.value.toLowerCase() === 'investor' && module.is_investing ||
                e.target.value.toLowerCase() === 'working' && module.is_worked ||
                e.target.value.toLowerCase() === 'hold' && !module.is_worked ||
                e.target.value.toLowerCase() === 'free' && module.is_free ||
                e.target.value.toLowerCase() === 'pro' && !module.is_free ||
                e.target.value.toLowerCase() === 'crypto' && module.market === 'CRYPTO' ||
                e.target.value.toLowerCase() === 'stocks' && module.market !== 'CRYPTO' ||
                format_text(module.ID).includes(e.target.value.toLowerCase())
            )
        }));
    }

    const onClickAddStrategy = (strategy) => {
        const strategyId = strategy.ID;
        const showToExchange = strategy.market === 'CRYPTO';
        mobXStore.setKey('addStrategyID', strategyId);

        if (showToExchange) {
            const confirmed = Modal.confirm({
                title: 'Where do we copy the trades to?',
                style: {marginTop: "150px"},
                // content: 'Select where we will copy signals from this strategy',
                footer: (_, {OkBtn, CancelBtn}) => (
                    <>
                        <CancelBtn/>
                        <Button onClick={() => {
                            props.changeMenu('bots');
                            confirmed.destroy();
                        }}><ApiOutlined/> to Exchange</Button>
                        <Button onClick={() => {
                            props.changeMenu('bots_webhook');
                            confirmed.destroy();
                        }}><ApartmentOutlined/> to Webhook</Button>
                        {/*<OkBtn />*/}
                    </>
                ),
                // okText: 'to Exchange',
                // cancelText: 'to Webhook',
                // onOk: () => {
                //     props.changeMenu('bots');
                // },
                // onCancel: () => {
                //     props.changeMenu('bots_webhook');
                // },
            })
        } else {
            props.changeMenu('bots_webhook');
        }
    }

    useEffect(() => {
        setDemoMode(mobXStore.getKey('demo_mode'));
        setLoading(true);
        apiUser.getStrategy().then((response) => {
            if (response.result) {
                const res = JSON.parse(response.data); //.filter((item) => item.market !== 'CRYPTO');
                setStrategyList(res);
                setSearch(res);
                // console.log(response.data);
            } else {
                // console.log(response.error);
                apiUser.logout();
            }
            setLoading(false);
        });
    }, []);
    /*
    {
    "ID": 1,
    "user_id": 1,
    "is_worked": 0,
    "name": "BTCUSDT futures ST1",
    "market": "CRYPTO",
    "symbol": "BTCUSDT.P",
    "direction": "LONG",
    "time_frame": "1h",
    "pyramiding": 0,
    "pnl_annual": 77,
    "win_rate": 81,
    "profit_factor": 5.6,
    "draw_down": 6.3,
    "max_loss_orders_session": 2,
    "tv_url": "https://www.tradingview.com/chart/J5Lx6PD5/",
    "photo_url": "https://cdzv.com/strategy_img/BTCUSDT.P_67413.1__0.81_Example4_state_machine_priv_2024-03-08_12-29-06.png",
    "leverage": 1,
    "max_leverage": 10,
    "risk_level": 1,
    "tags": "BTC",
    "is_public": 1
}
     */
    return (
        <>
            <Button
                // type="primary"
                style={{
                    backgroundColor: 'rgb(222,223,250)',
                    position: 'fixed',
                    top: 20,
                    right: 20,
                    zIndex: 1000,
                    borderRadius: 50,
                    fontWeight: 700,
                    fontFamily: 'Terminal, monospace',
                    boxShadow: '0 0 10px 0 rgba(0,0,0,0.5)',
                }}
                onClick={() => setShowModal(true)}
            >
                <InfoCircleOutlined/> How to
            </Button>

            <Modal
                open={showModal}
                title="How to set up Copy Trade"
                width={800}
                onOk={() => setShowModal(false)}
                onCancel={() => setShowModal(false)}
            >
                <div>
                    <iframe
                        style={{width: '100%', height: '382px'}}
                        src="https://embed.app.guidde.com/playbooks/3qnW38VbJ4HNsZXXF226m1"
                        title="How to set up copy trade" frameBorder="0" referrerPolicy="unsafe-url"
                        allowFullScreen="true" allow="clipboard-write"
                        sandbox="allow-popups allow-popups-to-escape-sandbox allow-scripts allow-forms allow-same-origin allow-presentation"></iframe>
                </div>
            </Modal>
            <Row gutter={8} align={"middle"} justify={"center"}>
                <Col span={20}>
                    <Search
                        size="large"
                        allowClear
                        style={{marginTop: "40px", width: "500px"}}
                        placeholder="input search text"
                        onChange={onSearch}
                        enterButton
                        value={searchValue}
                    />
                    <div style={{marginTop: '-50px'}}>
                        <Tooltip
                            placement="top"
                            overlayInnerStyle={{
                                width: '400px',
                                backgroundColor: '#efefefef',
                                color: 'red',
                                fontSize: '20px'
                            }}
                            title={"In the 'Profile' section, the section with your Telegram name must be filled in"}>
                            <h2>
                                <Link to={TG_Channel_with_signals} target="_blank">
                                    🔗 Click and Join our Telegram to receive signals from working strategies
                                </Link>
                            </h2>
                        </Tooltip>
                        <div
                            style={{marginTop: "-30px"}}
                        >
                            {/*<ConfigProvider*/}
                            {/*    theme={{*/}
                            {/*        components: {*/}
                            {/*            Button: {*/}
                            {/*                colorPrimary: `linear-gradient(135deg, ${colorsButton1.join(', ')})`,*/}
                            {/*                colorPrimaryHover: `linear-gradient(135deg, ${getHoverColors(colorsButton1).join(', ')})`,*/}
                            {/*                colorPrimaryActive: `linear-gradient(135deg, ${getActiveColors(colorsButton1).join(', ')})`,*/}
                            {/*                lineWidth: 0,*/}
                            {/*            },*/}
                            {/*        },*/}
                            {/*    }}*/}
                            {/*>*/}
                            {/*    <Button*/}
                            {/*        type={"primary"}*/}
                            {/*        size={"large"}*/}
                            {/*        style={{*/}
                            {/*            color: 'white',*/}
                            {/*            fontWeight: 700,*/}
                            {/*        }}*/}
                            {/*        onClick={() => props.changeMenu('bots')}*/}
                            {/*    >*/}
                            {/*        Start copying trades*/}
                            {/*    </Button>*/}
                            {/*</ConfigProvider>*/}
                        </div>
                    </div>
                </Col>
                <Col>
                    <Space.Compact block direction={"horizontal"} wrap>
                        <Tag key={'t0'}
                             color={searchValue === "" ? "blue-inverse" : "gray"}
                             style={{cursor: "pointer", fontSize: '16px'}}
                             onClick={() => {
                                 onSearch({
                                     target: {
                                         value: ""
                                     }
                                 })
                             }}
                        >
                            {searchValue === "" ? <strong>ALL</strong> : "ALL"}
                        </Tag>
                        <Tag key={'tfree'}
                             color={searchValue === "FREE" ? "blue-inverse" : "gray"}
                             style={{cursor: "pointer", fontSize: '16px'}}
                             onClick={() => {
                                 onSearch({
                                     target: {
                                         value: "FREE"
                                     }
                                 })
                             }}
                        >
                            {searchValue === "" ? <strong>FREE</strong> : "FREE"}
                        </Tag>
                        <Tag key={'tpro'}
                             color={searchValue === "PRO" ? "blue-inverse" : "gray"}
                             style={{cursor: "pointer", fontSize: '16px'}}
                             onClick={() => {
                                 onSearch({
                                     target: {
                                         value: "PRO"
                                     }
                                 })
                             }}
                        >
                            {searchValue === "" ? <strong>PRO</strong> : "PRO"}
                        </Tag>
                        <Tag key={'t1'}
                             color={searchValue === "TRADER" ? "blue-inverse" : "gray"}
                             style={{cursor: "pointer", fontSize: '16px'}}
                             onClick={() => {
                                 onSearch({
                                     target: {
                                         value: "TRADER"
                                     }
                                 })
                             }}
                        >
                            {searchValue === "TRADER" ? <strong>TRADER</strong> : "TRADER"}
                        </Tag>
                        <Tag key={'t2'}
                             color={searchValue === "INVESTOR" ? "blue-inverse" : "gray"}
                             style={{cursor: "pointer", fontSize: '16px'}}
                             onClick={() => {
                                 onSearch({
                                     target: {
                                         value: "INVESTOR"
                                     }
                                 })
                             }}
                        >
                            {searchValue === "INVESTOR" ? <strong>INVESTOR</strong> : "INVESTOR"}
                        </Tag>
                        {/*<Tag key={'t3'}*/}
                        {/*     color={"green"}*/}
                        {/*     style={{cursor: "pointer", fontSize: '16px'}}*/}
                        {/*     onClick={() => {*/}
                        {/*         onSearch({*/}
                        {/*             target: {*/}
                        {/*                 value: "WORKING"*/}
                        {/*             }*/}
                        {/*         })*/}
                        {/*     }}*/}
                        {/*>*/}
                        {/*    WORKING*/}
                        {/*</Tag>*/}
                        {/*<Tag key={'t4'}*/}
                        {/*     color={"red"}*/}
                        {/*     onClick={() => {*/}
                        {/*         onSearch({*/}
                        {/*             target: {*/}
                        {/*                 value: "HOLD"*/}
                        {/*             }*/}
                        {/*         })*/}
                        {/*     }}*/}
                        {/*>*/}
                        {/*    ON HOLD*/}
                        {/*</Tag>*/}
                        <Tag key={'t5'}
                             color={searchValue === "CRYPTO" ? "blue-inverse" : "gray"}
                             style={{cursor: "pointer", fontSize: '16px'}}
                             onClick={() => {
                                 onSearch({
                                     target: {
                                         value: "CRYPTO"
                                     }
                                 })
                             }}
                        >
                            {searchValue === "CRYPTO" ? <strong>CRYPTO</strong> : "CRYPTO"}
                        </Tag>
                        <Tag key={'t6'}
                             color={searchValue === "STOCKS" ? "blue-inverse" : "gray"}
                             style={{cursor: "pointer", fontSize: '16px'}}
                             onClick={() => {
                                 onSearch({
                                     target: {
                                         value: "STOCKS"
                                     }
                                 })
                             }}
                        >
                            {searchValue === "STOCKS" ? <strong>STOCKS</strong> : "STOCKS"}
                        </Tag>
                        <Tag key={'t7'}
                             color={searchValue === "SHORT" ? "blue-inverse" : "gray"}
                             style={{cursor: "pointer", fontSize: '16px'}}
                             onClick={() => {
                                 onSearch({
                                     target: {
                                         value: "SHORT"
                                     }
                                 })
                             }}
                        >
                            {searchValue === "SHORT" ? <strong>SHORT</strong> : "SHORT"}
                        </Tag>
                        <Tag key={'t8'}
                             color={searchValue === "LONG" ? "blue-inverse" : "gray"}
                             style={{cursor: "pointer", fontSize: '16px'}}
                             onClick={() => {
                                 onSearch({
                                     target: {
                                         value: "LONG"
                                     }
                                 })
                             }}
                        >
                            {searchValue === "LONG" ? <strong>LONG</strong> : "LONG"}
                        </Tag>
                    </Space.Compact>
                </Col>
            </Row>
            <Skeleton loading={loading}>
                <Row
                    gutter={[12, 12]}
                    align={"middle"}
                    justify={"center"}
                    style={{marginTop: 10, marginBottom: 10}}
                >
                    {search.map((strategy, index) => (
                        <Col xl={10} xs={22} lg={22} md={22} sm={22}>
                            <Card size={"small"}
                                // title={<>#ID_{format_text(strategy.ID)} | {strategy.market}</>}
                            >
                                <Badge.Ribbon
                                    placement="start"
                                    text={strategy.is_free ? "FREE" : "PRO"}
                                    color={strategy.is_free ? "red" : "green"}
                                >
                                    <Badge.Ribbon
                                        placement="end"
                                        text={strategy.is_investing ? "INVESTOR" : "TRADER"}
                                        color={strategy.is_investing ? "blue" : "magenta"}
                                    >
                                        {/*<Tooltip title='Click to go to the ready-made strategy'>*/}
                                        {/*<a href={strategy.tv_url} target="_blank" rel="noreferrer">*/}
                                        <StrategyCard strategy={strategy}/>
                                        {/*</a>*/}
                                        <ConfigProvider
                                            theme={{
                                                components: {
                                                    Button: {
                                                        colorPrimary: `linear-gradient(135deg, ${colorsButton1.join(', ')})`,
                                                        colorPrimaryHover: `linear-gradient(135deg, ${getHoverColors(colorsButton1).join(', ')})`,
                                                        colorPrimaryActive: `linear-gradient(135deg, ${getActiveColors(colorsButton1).join(', ')})`,
                                                        lineWidth: 0,
                                                    },
                                                },
                                            }}
                                        >
                                            <Button
                                                type="primary"
                                                size="large"
                                                onClick={(e) => {
                                                    Modal.info(
                                                        {
                                                            title: 'Backtest',
                                                            content: (
                                                                <>
                                                                    <Image src={strategy.photo_url}
                                                                           width={"100%"}
                                                                           preview={false}
                                                                    />
                                                                </>
                                                            ),
                                                            width: '95%',
                                                            style: {marginTop: "-90px"},
                                                        }
                                                    )
                                                }}
                                            >
                                                <AreaChartOutlined/> Backtest
                                            </Button> <Button type="primary"
                                                              size="large"
                                                              style={{marginTop: 10}}
                                                              onClick={e => {
                                                                  e.preventDefault();
                                                                  if (!demoMode) {
                                                                      window.open(strategy.tv_url, '_blank');
                                                                  } else {
                                                                      showModalDemoWarning();
                                                                  }
                                                              }}>
                                            <LinkOutlined/> TradingView
                                        </Button>
                                            {(strategy.is_copy_trade === 1 && strategy.market === 'CRYPTO') &&
                                                (<>
                                                        &nbsp;<Button type="primary"
                                                                      size="large"
                                                                      style={{marginTop: 10}}
                                                                      onClick={e => {
                                                                          e.preventDefault();
                                                                          onClickAddStrategy(strategy)
                                                                      }}>
                                                        <RobotOutlined/> Copy trades
                                                        ({strategy.market === 'CRYPTO' ? <><span
                                                        style={{color: "#8fef1b", fontWeight: 700}}>E</span> | <span
                                                        style={{color: "#ffabad", fontWeight: 700}}>W</span></> : <span
                                                        style={{color: "#ffabad", fontWeight: 700}}>W</span>})
                                                    </Button>
                                                    </>
                                                )}
                                        </ConfigProvider>
                                        {/*</Tooltip>*/}
                                    </Badge.Ribbon>
                                </Badge.Ribbon>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Skeleton>
        </>
    );
}

export default Strategy;